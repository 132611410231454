<template>
  <v-container>
    <v-progress-linear v-if="numBookings == null || numBookings !== numToursLoaded" indeterminate color="orange darken-3"></v-progress-linear>
    <div class="g-font renedian-red--text mb-6 font-size-22">My Tours</div>
    <div v-if="numBookings == 0 && numToursLoaded == numBookings">
      <v-alert class="mt-6" style="border: 1px solid #ef6c02">
        <div class="d-flex flex-row align-center">
          <div>You have not yet booked any tours.</div>
          <v-spacer></v-spacer>
          <v-btn @click="go('Booking')" color="orange darken-3" dark>Book a Tour</v-btn>
        </div>
      </v-alert>
    </div>
    <div v-if="numBookings > 0">
      <v-card v-for="(booking, index) in bookings" :key="'booking-'+booking.id" class="mb-6">
        <div class="d-flex flex-row">
          <div v-if="booking.tour.loading == false" class="roadbook d-none d-lg-block" @click="go('PortalTour', {index: index})">
            <div class="cover d-flex flex-column">
              <div class="flex-1 d-flex flex-column align-center justify-center white--text pa-4">
                <img class="badge" :src="booking?.tour?.template?.images?.badge">
                <!-- <h2 class="mt-12 text-center mb-6">{{ booking?.tour?.details?.name }}</h2> -->
                <h2 class="font-weight-light mt-12 text-center">
                  {{ booking.schedule.start | humanDate }} -<br>
                  {{ booking.schedule.finish | humanDate }}
                </h2>
              </div>
              <div class="d-flex justify-center mb-6">
                <img width="80" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Flogo-white.png?alt=media&token=20344e45-e1a9-4acb-abe4-cce5d55be6e0">
              </div>
            </div>
            <div class="mask"></div>
            <div class="photo" :style="{ 'background-image': 'url(' + booking?.tour?.template?.images?.brochure + ')' }"></div>
          </div>
          <div class="flex-1 pa-6">
            <div class="roadbanner d-lg-none mb-6" :style="{ 'background-image': 'url(' + booking?.tour?.template?.images?.banner + ')' }">
              <img class="badge" :src="booking?.tour?.template?.images?.badge">
            </div>
            <h2>{{ booking?.tour?.details?.name }}</h2>
            <v-divider></v-divider>
            <v-sheet class="mt-6 pa-6 d-flex flex-column" rounded color="grey lighten-4">
              <div class="mb-2">Countdown to Adventure</div>
              <h1>{{ countdown(booking.schedule.start) }}</h1>
              <div class="text-caption">{{ booking.schedule.start | humanDate }}</div>
            </v-sheet>
            <v-sheet class="mt-6 pa-6 d-flex flex-column" rounded color="grey lighten-4">
              <div class="mb-2">Remaining Balance</div>
              <h2>$12,345.67</h2>
              <div class="d-flex flex-row mt-4 align-center">
                <v-btn @click="go('PortalTour', {index: index, tab: 4})">Make Payment</v-btn>
                <div class="text-caption ml-4">Due by: {{ booking.schedule.start | dueDate }}</div>
              </div>
            </v-sheet>
            <v-sheet class="mt-6 pa-6 blue--text text--darken-2" rounded color="blue lighten-4">
              <div class="mb-2">Action Items</div>
              <h3>12 items require your attention</h3>
              <div class="d-flex flex-row mt-4 align-center">
                <v-btn @click="go('PortalTour', {index: index, tab: 0})" color="blue" dark>Take Action</v-btn>
              </div>
            </v-sheet>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import { getFirestore, getDocs, onSnapshot, collection, doc } from 'firebase/firestore'
import { DateTime } from 'luxon'
export default {
  name: 'PortalDashboard',
  props: ['user', 'bookings'],
  data: () => ({
    numBookings: null,
    tab: 0,
    first: false,
    items: [
      {
        color: 'red lighten-2',
        icon: 'mdi-star',
      },
      {
        color: 'purple darken-1',
        icon: 'mdi-book-variant',
      },
      {
        color: 'green lighten-1',
        icon: 'mdi-airballoon',
      },
      {
        color: 'indigo',
        icon: 'mdi-buffer',
      },
    ],
  }),
  methods: { 
    loadTours() {
      this.bookings.forEach( booking => {
        if (booking.tour.loading == undefined || booking.tour.loading == false) {
          booking.tour.loading = true
          const db = getFirestore();
          // Extract the one tour from the collection with that ID
          const docRef = doc(db, 'tours', booking.tour.id)
          onSnapshot( docRef, (doc) => {
            let tour = doc.data()
            tour.id = doc.id
            booking.tour = tour
            booking.tour.loading = false
          })
        }
      })
    },
    countdown(date) {
      // Express like "123 days, 4 hours, 5 minutes"
      // return DateTime.fromISO(date).diffNow().toFormat('d\' days, \'h\' hours, \'m\' minutes')
      return DateTime.fromISO(date).diffNow().toFormat('d\' days') + ' remaining'
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // console.log('Route changed', vm.bookings)
      if (vm.bookings.length) {
        vm.numBookings = vm.bookings.length
        vm.loadTours()
      } else {
        vm.numBookings = 0
      }
    });
  },
  watch: {
    bookings: function() {
      this.first = true
      // console.log('dashboard bookings changed')
      this.numBookings = this.bookings.length
      this.loadTours()
    },
  },
  filters: {
    humanDate: function (value) {
      // return DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL)
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy')
    },
    dueDate(value) {
      // 60 days before the start date
      return DateTime.fromISO(value).minus({ days: 60 }).toFormat('MMMM d, yyyy')
    },
  },
  computed: {
    numToursLoaded() {
      return this.bookings.filter(booking => booking.tour.loading == false).length
    }
  }
}
</script>
<style lang="scss" scoped>
  .portal-heading {
    background: #e3e3e3;
    min-height: 100px;
  }
  .portal-feed {
    background: white;
  }
  .roadbook {
    position: relative;
    width: 470px;
    height: 620px;
    cursor: pointer;
    .cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      padding: 10px 0 10px 20px;
      .badge {
        position: relative;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      }
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fportal%2Froadbook-mask.png?alt=media&token=79a127eb-5bcb-426d-907b-4fd3d02e347e');
      z-index: 1;
    }
    .photo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
  .roadbanner {
    height: 200px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>