<template>
  <v-footer padless>
    <v-col v-if="environment== 'Production'" class="text-center text-caption" cols="12">
      &copy; {{ year }} Renedian Motorcycle Tours
    </v-col>
    <v-col v-else class="text-center text-caption text-sm-subtitle-2" cols="12">
      &copy; {{ year }} {{ name }} | {{ code }} v{{ version }} | {{ environment }}
    </v-col>
  </v-footer>
</template>
<script>
  export default {
    name: 'Footer',
    data() {
      return {
        year: new Date().getFullYear(),
        name: process.env.VUE_APP_NAME,
        code: process.env.VUE_APP_CODENAME,
        version: process.env.VUE_APP_VERSION,
        environment: ''
      }
    },
    created() {
      this.environment = 'Local'
      if (window.location.hostname == process.env.VUE_APP_DEVELOPMENT_HOSTNAME) this.environment = 'Development'
      if (window.location.hostname == process.env.VUE_APP_PRODUCTION_HOSTNAME) this.environment = 'Production'
    }
  }
</script>