<template>
  <div v-if="booking" id="invoice" class="invoice-container pa-12">
    <div class="d-flex flex-row">
      <div class="d-flex flex-1 g-font font-size-30 align-center">Invoice</div>
      <div class="flex-1 d-flex justify-end">
        <img style="height: 75px" src="@/assets/images/renedian-logo.png" @click="getImage()">
      </div>
    </div>
    <div v-if="booking.invoices.length" class="d-flex flex-row mt-12">
      <div v-if="booking.guests.length == 2 && booking.guests[1].address.billing" class="d-flex flex-2 flex-column">
        <div class="font-weight-bold font-size-16">{{ booking.guests[1].name.first }} {{ booking.guests[1].name.last }}</div>
        <div>{{ booking.guests[1].address.street }}</div>
        <div>{{ booking.guests[1].address.city }}, {{ booking.guests[1].address.province }}</div>
        <div>{{ booking.guests[1].address.postal }}, {{ booking.guests[1].address.country }}</div>
        <div>{{ booking.guests[1].contact.email }}</div>
      </div>
      <div v-else class="d-flex flex-2 flex-column">
        <div class="font-weight-bold font-size-16">{{ booking.guests[0].name.first }} {{ booking.guests[0].name.last }}</div>
        <div>{{ booking.guests[0].address.street }}</div>
        <div>{{ booking.guests[0].address.city }}, {{ booking.guests[0].address.province }}</div>
        <div>{{ booking.guests[0].address.postal }}, {{ booking.guests[0].address.country }}</div>
        <div>{{ booking.guests[0].contact.email }}</div>
      </div>
      <div class="d-flex flex-1 flex-column grey-box">
        <div class="d-flex flex-row">
          <div class="pa-2 min-width-130 grey-background">Invoice Number</div>
          <div class="pa-2 min-width-150">{{ booking.invoices[0].number }}</div>
        </div>
        <div class="d-flex flex-row grey-top">
          <div class="pa-2 min-width-130 grey-background">Invoice Date</div>
          <div class="pa-2 min-width-150">{{ booking.invoices[0].date | formatDate }}</div>
        </div>
        <div class="d-flex flex-row grey-top">
          <div class="pa-2 min-width-130 grey-background">Total (CAD)</div>
          <div class="pa-2 min-width-150">{{ booking.invoices[0].amount.total | currency }}</div>
        </div>
        <div class="d-flex flex-row grey-top">
          <div class="pa-2 min-width-130 grey-background">Status</div>
          <div class="pa-2 min-width-150">Partial Payment</div>
        </div>
      </div>
    </div>

    <div v-if="booking.tour" class="renedian-burnt-orange--text font-size-24 font-weight-bold mt-6">
      {{ booking.tour.name }}
    </div>
    <div v-if="booking.schedule" class="font-size-22 font-weight-bold mb-6">
      {{ booking.schedule.start | formatDate }} - {{ booking.schedule.finish | formatDate }}
    </div>

    <div class="d-flex flex-column grey-box">
      <div class="d-flex flex-row">
        <!-- <div class="pa-2 grey-background font-weight-bold min-width-50 text-center grey-right">Qty</div> -->
        <div class="pa-2 grey-background font-weight-bold flex-1 grey-right">Description</div>
        <div class="pa-2 grey-background font-weight-bold min-width-130 text-center grey-right">Amount</div>
        <!-- <div class="pa-2 grey-background font-weight-bold min-width-130 text-center">Amount</div> -->
      </div>
      <div v-for="(category, categoryIndex) in booking.charges" :key="'category'+categoryIndex">
        <div v-for="(charge, chargeIndex) in category.items" :key="'charge'+chargeIndex">
          <div v-for="(item, itemIndex) in charge.quantity" :key="'item'+itemIndex" class="d-flex flex-row">
            <!-- <div class="pa-2 min-width-50 text-center grey-top grey-right">{{ charge.quantity }}</div> -->
            <div v-if="category.name == 'Vehicles'" class="pa-2 flex-1 grey-top grey-right">{{ charge.name }}</div>
            <div v-else class="pa-2 flex-1 grey-top grey-right">{{ category.name }} - {{ charge.name }}</div>
            <div class="pa-2 min-width-130 text-center grey-top grey-right">{{ charge.amount | currency }}</div>
            <!-- <div class="pa-2 min-width-130 text-center grey-top">{{ charge.amount * charge.quantity | currency }}</div> -->
          </div>
        </div>
      </div>
    </div>

    <div v-if="booking.invoices.length" class="d-flex flex-row">
      <div class="flex-1 pa-4 font-size-10">
        <div class="font-weight-bold">Payment Schedule</div>
        <div>Remaining balance due by <span class="renedian-burnt-orange--text font-weight-bold">{{ balanceDue | formatDate }}</span> (60 days before tour start)</div>

        <div class="font-weight-bold mt-6">Terms & Conditions</div>
        <div>Available online at https://renedian.com/terms-and-conditions</div>
      </div>
      <div class="d-flex flex-column grey-box" style="border-top: none">
        <div class="d-flex flex-row">
          <div class="pa-2 min-width-130 text-center grey-background grey-right">Total</div>
          <div class="pa-2 min-width-130 text-center">{{ booking.invoices[0].amount.subtotal | currency }}</div>
        </div>
        <div v-if="booking.invoices[0].amount.tax > 0" class="d-flex flex-row">
          <div class="pa-2 min-width-130 text-center grey-background grey-right">Tax</div>
          <div class="pa-2 min-width-130 text-center">{{ booking.invoices[0].amount.tax | currency }}</div>
        </div>
        <div class="d-flex flex-row grey-top">
          <div class="pa-2 min-width-130 text-center grey-background grey-right">Payment</div>
          <div class="pa-2 min-width-130 text-center">{{ booking.invoices[0].amount.total * 0.10 | currency }}</div>
        </div>
        <div class="d-flex flex-row grey-top">
          <div class="pa-2 min-width-130 text-center grey-background grey-right">Balance</div>
          <div class="pa-2 min-width-130 text-center">{{ booking.invoices[0].balance - booking.invoices[0].amount.total * 0.10 | currency }}</div>
        </div>
      </div>
    </div>

    <div class="font-size-22 text-center my-12">
      Thank you for your business!
    </div>

    <div class="d-flex flex-row font-size-10">
      <div class="flex-1">
        Renedian Motorcycle Tours | PO Box 41, Summerland, British Columbia, V0H 1Z0, Canada
      </div>
      <div>
        info@renedian.com | +1 (778) 516-0059
      </div>
    </div>
    <div class="d-flex flex-row font-size-10">
      Consumer Protection BC Agency Licence 78258
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon"
export default {
  props: ['booking'],
  computed: {
    balanceDue() {
      // Due 60 days before
      return DateTime.fromISO(this.booking.schedule.start).minus({ days: 60 }).toISODate()
    }
  },
  filters: {
    formatDate(value) {
      // return DateTime.fromISO(value).toFormat('mm /dd/yy')
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy')
    },
    currency(value) {
      if (!value) return 'C$0';
      const formattedValue = Math.abs(Number(value)).toFixed(2).replace(/\d(?=(\d{3})+\b)/g, '$&,');
      return 'C$' + formattedValue;
    },
  },
};
</script>

<style scoped>
  #invoice {
    position: absolute;
    left: -5000px;
    top: 0;
    width: 600px;
    height: auto;
    /* visibility: hidden; */
  }
  .invoice-container {
    margin: 0 auto;
    max-width: 800px;
    min-width: 800px;
  }
  .grey-box {
    border: 1px solid #ccc;
  }
  .grey-top {
    border-top: 1px solid #efefef;
  }
  .grey-right {
    border-right: 1px solid #efefef;
  }
  .grey-background {
    background: #f3f3f3
  }
  .min-width-50 {
    min-width: 50px;
  }
  .min-width-100 {
    min-width: 100px;
  }
  .min-width-130 {
    min-width: 130px;
  }
  .min-width-150 {
    min-width: 150px;
  }
</style>
