<template>
  <v-card>
    <v-progress-linear v-if="loading" color="orange" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-eye</v-icon>
      Overview
      <v-spacer></v-spacer>
      <!-- <v-btn dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Restaurant</v-btn> -->
    </v-card-title>
    <v-card-text>
      <v-alert type="warning">
        
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdminInsightsOverview',
  data: () => ({
    loading: false
  }),
  created() {
    
  },
  methods: {
    
  },
}
</script>
