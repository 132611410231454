<template>
  <div class="hero">
    <!-- ---------------------- Navigation Drawer ---------------------- -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <img class="logo" src="../../assets/logo-white.png">
          </v-list-item-title>
          <v-list-item-subtitle>
            Renedian Adventures
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="menuGroup" active-class="orange--text text--accent-4"> 
          <v-list-item v-for="(item, index) in menu" :key="'drawer-menu-' + index">
            <v-list-item-title @click="go(item.route)">{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(item, index) in submenu" :key="'drawer-submenu-' + index">
            <v-list-item-title @click="go(item.route)">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div class="px-2">
        <v-btn block class="mb-2" @click="go('Portal')" depressed tile>Rider Portal</v-btn>
        <v-btn block class="mt-2" color="orange" @click="go('Booking')" depressed tile>Book Now</v-btn>
      </div>
    </v-navigation-drawer>
    <!-- ---------------------- Header ---------------------- -->
    <div class="d-flex flex-row pa-4 align-center">
      <div class="d-flex flex-1 align-center">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-3 d-flex d-sm-none" dark></v-app-bar-nav-icon>
        <img class="logo" src="../../assets/logo-white.png">
      </div>
      <!-- ------------ Header Menu ------------ -->
      <div class="flex-column d-none d-sm-flex">
        <div class="d-flex flex-row">
          <v-btn v-for="item in menu" :key="item.id" small dark text @click="go(item.route)">
            {{ item.title }}
          </v-btn>
        </div>
        <div class="d-flex justify-end">
          <v-btn v-for="item in submenu" :key="item.id" x-small dark text @click="go(item.route)">
            {{ item.title }}
          </v-btn>
        </div>
      </div>
      <!-- ------------ Header Call to Action ------------ -->
      <div>
        <v-btn small class="ml-2" @click="go('Portal')" depressed tile>Rider Portal</v-btn>
        <v-btn small class="ml-2" color="orange" @click="go('Booking')" depressed tile>Book Now</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ExperimentalTemplate',
  data: () => ({
    drawer: false,
    menuGroup: null,
    menu: [
      { id: 1, title: 'Tours', icon: 'mdi-view-dashboard', route: 'Tours' },
      { id: 2, title: 'Choosing Renedian', icon: 'mdi-view-dashboard', route: 'Choose' },
      { id: 3, title: 'Rider Tales', icon: 'mdi-view-dashboard', route: 'Choose' },
    ],
    submenu: [
      { id: 3, title: 'Rentals', icon: 'mdi-view-dashboard', route: 'Rentals' },
      { id: 4, title: 'Gear', icon: 'mdi-view-dashboard', route: 'Gear' },
    ]
  }),
  created() {
    
  },
  methods: {
    
  }
}
</script>
<style lang="scss">
  .hero {
    height: 600px;
    overflow: hidden;
    // background: url('../../assets/south-africa.jpg');
    background: #9e9e9e;
    background-position-y: center;
    background-size: cover;
    .logo {
      height: 40px;
    }
  }
</style>