<template>
  <v-card>
    <v-progress-linear v-if="loading" color="orange" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-currency-usd</v-icon>
      Costing
      <v-spacer></v-spacer>
      <!-- <v-btn dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Restaurant</v-btn> -->
    </v-card-title>
    <v-card-text>
      <v-alert type="warning">
        @Piet: Are you able to provide a list 
      </v-alert>
      <v-alert type="info">
        <strong>Implement Piet's spreadsheets</strong>
        <ul>
          <li>22 Day CPT to VF Paved - Costing 2023 -> Costing - 22 day CPT VF-PAVED</li>
          <li>22 Day CPT to VF Paved - Costing 2023 -> CPT - VF PAVED ITINERARY</li>
          <li>22 Day CPT to VF Paved - Costing 2023 -> Welcome Pack</li>
        </ul>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdminCosting',
  data: () => ({
    loading: false
  }),
  created() {
    
  },
  methods: {
    
  },
}
</script>
