import { render, staticRenderFns } from "./ToursManage.vue?vue&type=template&id=40d9fc2e&scoped=true"
import script from "./ToursManage.vue?vue&type=script&lang=js"
export * from "./ToursManage.vue?vue&type=script&lang=js"
import style0 from "./ToursManage.vue?vue&type=style&index=0&id=40d9fc2e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d9fc2e",
  null
  
)

export default component.exports