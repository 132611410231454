<template>
  <v-card>
    <v-progress-linear v-if="loading" color="orange" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-view-dashboard</v-icon>
      Dashboard
      <v-spacer></v-spacer>
      <!-- <v-btn dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Restaurant</v-btn> -->
    </v-card-title>
    <v-card-text>
      <v-alert type="info">
        <strong>While a guide is on tour</strong><br>
        <em>Static Information</em><br>
        <ul>
          <li>itinerary</li>
          <li>Hotel Confirmation Details + contact details</li>
          <li>Restaurant Confirmation Details + contact details</li>
          <li>Activity Confirmation Details + contact details</li>
          <li>Talking Points during evening briefing (for following day)</li>
        </ul>
        <em>Dynamic Information</em><br>
        <ul>
          <li>Live weather for the location to be pulled in here - pushed to Whatsapp group.</li>
        </ul>
        <em>Information Collection</em><br>
        <ul>
          <li>Survey/Comment Box  to be completed by guide about that day (in lieu of doing guide notes)</li>
          <li>Guide needs to be able to send receipts in during trip</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>When a tour is upcoming</strong>
        <ul>
          <li>download rider manifests</li>
          <li>download itinerary</li>
          <li>Chronological list of upcoming tours for this employee</li>
          <li>Employee actions and todo items (found in document: Backend Development Specifications - Renedian)</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <ul>
          <li>System to provide the ability to generate a schedule / year planner with “everything” on it, i.e.:
            <ul>
              <li>Each scheduled tour date in the Renedian Portfolio to listed /accessed from here</li>
              <li>Tradeshow events and other marketing opportunities to be attended by Renedian Staff</li>
              <li>Other important / relevant events and dates TBD</li>
              <li>All of the above to be visible on one sheet/page to simplify forward planning</li>
            </ul>
          </li>
          <!-- <li>Scheduling also to be integrated with workflow component in the sense that it will create reminders for each staff member in the workflow process to send out (a pre-populated) email at the relevant stage in the onboarding process of a rider on tour</li> -->
          <!-- <li>Reminder also for admin staff to appear when they have to secure a booking (i.e. one year or two years ahead before tour date, reminder to book a specific hotel)</li> -->
          <!-- <li>Specific tour database required for each tour. This must allow Danise / Renedian staff to easily access (in one place) information such as Tour Itinerary</li> -->
          <li>Rider details (flights, airport transfers, all other relevant rider details i.e. place passport, email, contact, t-shirt size) etc.</li>
          <li>Database with ALL vendors: contact details etc.</li>
          <li>Database to contain ALL emergency numbers in each area of riding </li>
          <!-- <li>Portal to integrate with year planner/scheduling (as referred to above) to serve as reminder for guide regarding his / her schedule of when to be ion tour. When to attend trade show events etc.</li> -->
        </ul>
      </v-alert>
    </v-card-text>
    <v-card-text>
      <div class="d-flex flex-row">
        <div class="flex-1">
          <v-treeview
            shaped
            hoverable
            activatable
            :items="collections"
            open-all
          ></v-treeview>
        </div>
        <div class="flex-1">
          <v-treeview
            shaped
            hoverable
            activatable
            :items="models"
          ></v-treeview>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdminDashboard',
  data: () => ({
    loading: false,
    // ------------ Temporary Stuff ------------
    // ------------ Collections ------------
    collections: [
      {
        id: 'collection.adventures',
        name: 'Adventures : Collection',
        children: [
          // 
        ],
      },
      {
        id: 'collection.tours',
        name: 'Tours : Collection',
        children: [
          // Bookings
          // Automation
        ]
      },
      {
        id: 'collection.locations',
        name: 'Locations : Collection',
        children: [
          // 
        ],
      },
      {
        id: 'collection.vehicles',
        name: 'Vehicles : Collection',
        children: [
          // 
        ],
      },
      {
        id: 'collection.hotels',
        name: 'Hotels : Collection',
        children: [
          // 
        ],
      },
      {
        id: 'collection.restaurants',
        name: 'Restaurants : Collection',
        children: [
          // 
        ],
      },
      {
        id: 'collection.activities',
        name: 'Activities : Collection',
        children: [
          // 
        ],
      },
      {
        id: 'collection.customers',
        name: 'Customers : Collection',
        children: [
          // 
        ],
      },
      {
        id: 'collection.employees',
        name: 'Employees : Collection',
        children: [
          // 
        ],
      },
      {
        id: 'collection.messages',
        name: 'Messages : Collection',
        children: [
          // 
        ],
      },
      {
        id: 'collection.automations',
        name: 'Automations : Collection',
        children: [
          // 
        ],
      },
    ],
    // ------------ Models ------------
    models: [
      {
        id: 'model.adventure',
        name: 'Adventure : Model',
        children: [
          { id: 'model.adventure.details', name: 'Details' },
          { id: 'model.adventure.location', name: 'Location', children: [
            { id: 'model.adventure.location.crew', name: 'Crew' },
            { id: 'model.adventure.location.vehicles', name: 'Vehicles' },
            { id: 'model.adventure.location.hotels', name: 'Hotels' },
            { id: 'model.adventure.location.restaurants', name: 'Restaurants' },
            { id: 'model.adventure.location.activities', name: 'Activities' },
          ]},
          { id: 'model.adventure.checklists', name: 'Checklists' },
          { id: 'model.adventure.excursion', name: 'Excursion' },
        ],
      },
      {
        id: 'model.tour',
        name: 'Tour : Model',
        children: [
          { id: 'model.tour.details', name: 'Details' },
          { id: 'model.tour.adventure', name: 'Adventure' },
          { id: 'model.tour.schedule', name: 'Schedule' },
          { id: 'model.tour.itinerary', name: 'Itinerary' },
          { id: 'model.tour.checklist', name: 'Checklist' },
          { id: 'model.tour.excursion', name: 'Excursion' },
          { id: 'model.tour.bookings', name: 'Bookings', children: [
            { id: 'model.tour.customers', name: 'Customers' },
          ]},
        ],
      },
      {
        id: 'model.customer',
        name: 'Customer : Model',
        children: [
          // Details
          { id: 'model.customer.details', name: 'Details' },
          // Tours
          { id: 'model.customer.tours', name: 'Tours' },
          // Bills
          { id: 'model.customer.bills', name: 'Bills' },
          // Payments
          { id: 'model.customer.payments', name: 'Payments' },
        ],
      },

    ],
    // ------------ Master ------------
    master: [
      {
        id: 'model.tour',
        name: 'Tour : Model',
        children: [
          // Details
          // Adventure
            // Details
            // Location
              // Crew
              // Vehicles
              // Hotels
              // Restaurants
              // Activities
            // Checklists
            // Excursion
          // Schedule
          // Itinerary
          // Checklist
          // Excursion
          // Bookings
            // Customers
              // Details
              // Bills
              // Payments
        ],
      },


      {
        id: 'model.adventure',
        name: 'Adventure : Model',
        children: [
          // Details
          // Location
            // Crew
            // Vehicles
            // Hotels
            // Restaurants
            // Activities
          // Checklists
          // Excursion
        ],
      },
      
    ]
  }),
  created() {
    
  },
  methods: {
    
  },
}
</script>
