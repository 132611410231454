<template>
  <v-card>
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-silverware-variant</v-icon>
      Restaurants
      <v-spacer></v-spacer>
      <v-btn @click="openRestaurant('new')" dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Restaurant</v-btn>
    </v-card-title>
    <v-card-text v-if="!loading" class="mb-4">
      <div v-if="restaurants.length">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search Restaurants"
          single-line
          hide-details
          filled
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="filteredRestaurants"
          :items-per-page="20"
          class="elevation-1"
          @click:row="openRestaurant"
        >
          <!-- Override -->
          <template v-slot:item.meals="{ item }">
            {{ meals(item) }}
          </template>
        </v-data-table>
      </div>
      <div v-else class="mb-4">
        <v-alert type="info" outlined>There are no restaurants configured</v-alert>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { getFirestore, onSnapshot, collection } from 'firebase/firestore'
export default {
  name: 'AdminVendorsRestaurants',
  data: () => ({
    loading: true,
    // Data Table
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'contact.email' },
      { text: 'Meals', value: 'meals' },
      { text: 'Country', value: 'location.country' },
      { text: 'Continent', value: 'location.continent' },
    ],
    // 
    restaurants: [],
  }),
  created() {
    const db = getFirestore()
    const collectionRef = collection(db, 'restaurants')
    onSnapshot(collectionRef, (snapshot) => {
      this.loading = true
      let items = []
      snapshot.forEach((doc) => {
        let item = doc.data()
        item.id = doc.id
        items.push(item)
      })
      this.restaurants = items
      this.loading = false
    })
  },
  methods: {
    openRestaurant(item) {
      if (item.id) {
        this.go('AdminVendorsRestaurant', {'id': item.id})
      } else {
        this.go('AdminVendorsRestaurant', {'id': 'new'})
      }
    },
    meals(restaurant) {
      let meals = []
      if (restaurant.meals.breakfast.included) meals.push('breakfast')
      if (restaurant.meals.lunch.included) meals.push('lunch')
      if (restaurant.meals.dinner.included) meals.push('dinner')
      return meals.length ? meals.join(', ') : ''
    },
  },
  computed: {
    filteredRestaurants() {
      if (!this.search) {
        return this.restaurants
      }
      const searchLower = this.search.toLowerCase()
      return this.restaurants.filter(restaurant => {
        const name = restaurant.name.toLowerCase()
        const email = restaurant.contact.email.toLowerCase()
        const meals = this.meals(restaurant).toLowerCase()
        const country = restaurant.location.country.toLowerCase()
        const continent = restaurant.location.continent.toLowerCase()
        
        return (
          name.includes(searchLower) ||
          email.includes(searchLower) ||
          meals.includes(searchLower) ||
          country.includes(searchLower) ||
          continent.includes(searchLower)
        )
      })
    }
  },
}
</script>
