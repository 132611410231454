<template>
  <v-sheet>
    Featured Tours
  </v-sheet>
</template>
<script>
export default {
  name: 'FeaturedTours',
  data: () => ({
    //
  }),
  created() {
    
  }
}
</script>