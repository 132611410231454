<template>
  <v-card>
    <v-progress-linear v-if="loading" color="orange" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-account-question-outline</v-icon>
      Leads
      <v-spacer></v-spacer>
      <!-- <v-btn dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Restaurant</v-btn> -->
    </v-card-title>
    <v-card-text>
      <v-alert type="warning">
        @Rene: Can you provide an email template for this?
      </v-alert>
      <v-alert type="info">
        <strong>List of users who have expressed interest but have not made a payment</strong>
        <ul>
          <li>Link to email user</li>
          <li>Date</li>
          <li>Email address</li>
          <li>Step/Status</li>
          <li>Details (if they are available (ie. the bike they selected, etc.))</li>
          <li>Remove user</li>
        </ul>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdminLeads',
  data: () => ({
    loading: false
  }),
  created() {
    
  },
  methods: {
    
  },
}
</script>
