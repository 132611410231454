<template>
  <div>
    <v-card v-if="model">
      <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
      <v-card-title>
        <v-btn @click="go('AdminVendorsRestaurants')" icon class="mr-3"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-icon color="orange darken-3" left>mdi-silverware-variant</v-icon>
        <span>{{ model && model.name ? model.name : "Add Restaurant" }}</span>
      </v-card-title>
      <v-card-text>
        <v-alert type="success" v-if="success" dismissible>{{ success }}</v-alert>
        <v-alert type="error" v-if="error" dismissible>{{ error }}</v-alert>
        <!-- ------------ Primary Content Display ------------ -->
        <v-tabs v-model="tab">
          <v-tab>Details</v-tab>
          <v-tab>Meals</v-tab>
          <v-tab>Notes</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <!-- ------------ Details ------------ -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Establishment</div>
                <v-text-field
                  label="Name of Establishment"
                  v-model="model.name"
                ></v-text-field>
                <!-- <v-select
                  label="Type of Establishment"
                  v-model="model.type"
                  :items="establishmentTypes"
                ></v-select> -->
                <div class="overline mt-4">Currency</div>
                <v-autocomplete
                  :items="currencies"
                  label="Currency"
                  v-model="model.currency"
                  item-text="code"
                  return-object
                  @input="updateExchangeRate()"
                >
                  <template v-slot:item="{ item }">
                    {{ item.code }} - {{ item.name }}
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-if="model.currency && model.currency.code"
                  label="Exchange Rate"
                  v-model="model.currency.exchange"
                  :hint="
                    model.currency.symbol +
                    '1 ' +
                    model.currency.name +
                    ' = C$' +
                    model.currency.exchange +
                    ' Canadian Dollars'
                  "
                ></v-text-field>
                <div class="overline mt-4">Photo</div>
                <v-text-field label="Photo" v-model="model.photo"></v-text-field>
                <v-img
                  contain
                  :src="model.photo"
                  class="text-right"
                  height="150"
                  :style="{ background: model.photo ? 'white' : '#efefef' }"
                ></v-img>
              </div>
              <v-divider vertical></v-divider>
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Location</div>
                <v-text-field label="Address" v-model="model.location.address"></v-text-field>
                <div class="d-flex flex-row">
                  <v-text-field label="City" v-model="model.location.city"></v-text-field>
                  <v-text-field label="Province" v-model="model.location.province"></v-text-field>
                </div>
                <div class="d-flex flex-row">
                  <v-select
                    label="Country"
                    v-if="model.location.continent"
                    v-model="model.location.country"
                    :items="filtered_countries"
                    item-text="country"
                  ></v-select>
                  <v-select
                    label="Continent"
                    v-model="model.location.continent"
                    :items="continents"
                    item-text="name"
                  ></v-select>
                </div>
                <div class="overline mt-4">Contact</div>
                <v-text-field label="Name" v-model="model.contact.name"></v-text-field>
                <v-text-field label="Phone" v-model="model.contact.phone"></v-text-field>
                <v-text-field label="Mobile" v-model="model.contact.mobile"></v-text-field>
                <v-text-field label="Email" v-model="model.contact.email"></v-text-field>
                <v-text-field label="Website" v-model="model.contact.website"></v-text-field>
              </div>
            </div>
          </v-tab-item>
          <!-- ------------ Meals ------------ -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Meal Offerings</div>
                <v-chip-group
                  multiple
                  v-model="model.meals.index"
                  @input="filterChange()"
                  @change="filterChange()"
                >
                  <v-chip filter outlined>Breakfast</v-chip>
                  <v-chip filter outlined>Lunch</v-chip>
                  <v-chip filter outlined>Dinner</v-chip>
                </v-chip-group>

                <div class="overline mt-4">Meal Rates</div>
                <v-text-field
                  v-if="model.meals.breakfast.included"
                  label="Breakfast Rate"
                  v-model="model.meals.breakfast.cost"
                  class="mr-3"
                  :prefix="model.currency.symbol"
                  :suffix="model.currency.code"
                  hide-details
                ></v-text-field>
                <div v-if="model.meals.breakfast.included && model.meals.breakfast.cost > 0 && model.currency.exchange" class="text-right mr-3">
                  CAD${{ (model.meals.breakfast.cost * model.currency.exchange) | formatCurrency }}
                </div>
                <div v-else class="mt-6"></div>
                
                <v-text-field
                  v-if="model.meals.lunch.included"
                  label="Lunch Rate"
                  v-model="model.meals.lunch.cost"
                  class="mr-3"
                  :prefix="model.currency.symbol"
                  :suffix="model.currency.code"
                  hide-details
                ></v-text-field>
                <div v-if="model.meals.lunch.included && model.meals.lunch.cost > 0 && model.currency.exchange" class="text-right mr-3">
                  CAD${{ (model.meals.lunch.cost * model.currency.exchange) | formatCurrency }}
                </div>
                <div v-else class="mt-6"></div>
                
                <v-text-field
                  v-if="model.meals.dinner.included"
                  label="Dinner Rate"
                  v-model="model.meals.dinner.cost"
                  class="mr-3"
                  :prefix="model.currency.symbol"
                  :suffix="model.currency.code"
                  hide-details
                ></v-text-field>
                <div v-if="model.meals.dinner.included && model.meals.dinner.cost > 0 && model.currency.exchange" class="text-right mr-3">
                  CAD${{ (model.meals.dinner.cost * model.currency.exchange) | formatCurrency }}
                </div>
              </div>
              <v-divider vertical></v-divider>
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Dietary Offerings</div>
                <v-checkbox
                  v-model="model.dietaryOfferings"
                  v-for="diet in dietaryTypes"
                  :key="diet"
                  :label="diet"
                  :value="diet"
                  multiple
                  hide-details
                ></v-checkbox>
              </div>
            </div>
          </v-tab-item>
          <!-- ------------ Notes ------------ -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Special Notes</div>
                <v-textarea v-model="model.notes" outlined></v-textarea>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="model.id" small text color="red" @click="deleteDialog = true" :disabled="processing"><v-icon left>mdi-delete</v-icon>Delete Restaurant</v-btn>
        <v-btn v-else small text color="red" @click="go('AdminVendorsRestaurants')" :disabled="processing"><v-icon left>mdi-cancel</v-icon>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="model.id" color="primary" @click="update()" :loading="processing">
          <v-icon left>mdi-content-save-outline</v-icon>Save Restaurant
        </v-btn>
        <v-btn v-else color="primary" @click="create()" :loading="processing">
          <v-icon left>mdi-content-save-outline</v-icon>Add Restaurant
        </v-btn>
      </v-card-actions>
      <!-- ------------ Delete Adventure Dialog ------------ -->
      <v-dialog v-model="deleteDialog" width="400">
        <v-card>
          <v-card-title class="headline">Delete Restaurant</v-card-title>
          <v-card-text>
            <v-alert type="error">
              This action is permanent and cannot be undone. 
            </v-alert>
            <v-checkbox v-model="deletionConfirmed" color="red" label="I understand the consequences"></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="deleteDialog=false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text :disabled="!deletionConfirmed" @click="permanentlyDelete()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-alert v-else-if="error" type="error">
      {{ error }}
    </v-alert>
  </div>
</template>
<script>
import { getFirestore, doc, addDoc, setDoc, query, orderBy, onSnapshot, collection, deleteDoc } from 'firebase/firestore'
import axios from "axios";
export default {
  name: 'VendorsRestaurant',
  props: ["user"],
  data: () => ({
    // Common data
    tab: 0,
    currencies: [],
    continents: [
      "Africa",
      "Antarctica",
      "Asia",
      "Oceania",
      "Europe",
      "North America",
      "South America",
    ],
    establishmentTypes: ["Diner", "Steakhouse", "Guest House"],
    dietaryTypes: [
      "Vegetarian",
      "Vegan",
      "Gluten Free",
      "Dairy Free",
      "Kosher",
      "Halal",
    ],
    countries: [],
    loading: false,
    processing: false,
    success: "",
    error: "",
    deleteDialog: false,
    deletionConfirmed: false,
    // Unique data
    defaultModel: {
      id: null,
      name: "", // name of establishment
      type: "",
      currency: {
        local: "CAD",
        conversion: 1,
        primary: "CAD",
      },
      location: {
        address: "",
        city: "",
        postalCode: "",
        country: "",
        continent: "",
      },
      contact: {
        name: "",
        phone: "",
        mobile: "",
        email: "",
        website: "",
      },
      meals: {
        index: [],
        breakfast: {
          included: false,
          cost: 40,
        },
        lunch: {
          included: false,
          cost: 40,
        },
        dinner: {
          included: false,
          cost: 40,
        },
      },
      dietaryOfferings: [],
      notes: "",
      order: 0,
    },
    model: null,
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      // Load constant data
      this.countries = require("@/assets/continents.json");
      this.currencies = require("@/assets/currencies.json");
      // Load the id if it exists
      let id = this.$route.params.id;
      if (id == 'new') {
        this.model = JSON.parse(JSON.stringify(this.defaultModel));
      } else {
        this.loading = true;
        const db = getFirestore();
        const collectionRef = collection(db, 'restaurants');
        const docRef = doc(collectionRef, id);
        onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            this.model = doc.data();
            this.model.id = doc.id;
            this.loading = false;
          } else {
            this.error = "Restaurant not found.";
            this.model = null;
            this.loading = false;
          }
        });
      }
    },
    updateExchangeRate() {
      if (this.model.currency != undefined && this.model.currency.code) {
        let endpoint =
          "https://v6.exchangerate-api.com/v6/04107731562b261fdf9a93c8/pair/" +
          this.model.currency.code +
          "/CAD";
        axios.get(endpoint).then((response) => {
          if (response.status == 200 && response.data.result == "success") {
            this.model.currency.exchange = response.data.conversion_rate;
            // Update default meal rates
            this.model.meals.breakfast.cost = 15 * (1/this.model.currency.exchange);
            this.model.meals.breakfast.cost = this.model.meals.breakfast.cost.toFixed(2);
            this.model.meals.lunch.cost = 15 * (1/this.model.currency.exchange);
            this.model.meals.lunch.cost = this.model.meals.lunch.cost.toFixed(2);
            this.model.meals.dinner.cost = 40 * (1/this.model.currency.exchange);
            this.model.meals.dinner.cost = this.model.meals.dinner.cost.toFixed(2);
            // 
            this.$forceUpdate();
          } else {
            this.model.currency = null;
          }
        });
      }
    },
    filterChange() {
      this.model.meals.breakfast.included = this.model.meals.index.includes(0) ? true : false;
      this.model.meals.lunch.included = this.model.meals.index.includes(1) ? true : false;
      this.model.meals.dinner.included = this.model.meals.index.includes(2) ? true : false;
    },
    create() {
      this.success = false;
      this.error = false;
      this.processing = true;
      const db = getFirestore();
      const collectionRef = collection(db, 'restaurants');
      addDoc(collectionRef, this.model)
        .then((docRef) => {
          this.success = "Restaurant saved successfully.";
          this.model.id = docRef.id;
          this.processing = false;
        })
        .catch((error) => {
          this.error = "Error adding restaurant. Please try again.";
          this.processing = false;
        });
    },
    update() {
      this.loading = true;
      const db = getFirestore();
      const collectionRef = collection(db, 'restaurants');
      const docRef = doc(collectionRef, this.model.id);
      setDoc(docRef, this.model)
        .then((docRef) => {
          this.success = "Restaurant updated successfully.";
          this.loading = false;
        })
        .catch((error) => {
          this.error = "Error updating restaurant. Please try again.";
          this.loading = false;
        });
    },
    permanentlyDelete() {
      this.loading = true;
      const db = getFirestore();
      const collectionRef = collection(db, 'restaurants');
      const docRef = doc(collectionRef, this.model.id);
      deleteDoc(docRef)
        .then((docRef) => {
          // this.success = "Restaurant deleted successfully.";
          this.go('AdminVendorsRestaurants')
          this.loading = false;
        })
        .catch((error) => {
          this.error = "Error deleting hotel. Please try again.";
          this.loading = false;
        });
    },
  },
  watch: {
    user() {
      if (this.user) this.init();
    },
  },
  computed: {
    filtered_countries() {
      return this.countries.filter(
        (country) => country.continent == this.model.location.continent
      );
    },
  },
  filters: {
    formatCurrency(value) {
      return value.toFixed(2);
    },
  },
}
</script>