<template>
  <v-container>
    <div class="g-font renedian-red--text mb-6 font-size-22">Accounts & Billing</div>
    <v-tabs v-model="tab">
      <v-tab>Account</v-tab>
      <v-tab>Invoices</v-tab>
      <v-tab>Payments</v-tab>
    </v-tabs>
    <v-sheet class="pa-6">
      <v-tabs-items v-model="tab">
        <!-- Account -->
        <v-tab-item>
          <v-card class="ma-6">
            <v-card-text>
              - Balance<br>
              - Due Date<br>
              - Make Payment<br>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Invoices -->
        <v-tab-item>
          <v-card class="ma-6">
            <v-card-text>
              - Last Invoice<br>
              - Previous Invoices<br>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Payments -->
        <v-tab-item>
          <v-card class="ma-6">
            <v-card-text>
              - Last Payment<br>
              - Previous Payments<br>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-container>
</template>
<script>
export default {
  name: 'PortalBilling',
  props: ['user'],
  data: () => ({
    tab: 0,
  }),
  methods: {
    
  }
}
</script>