<template>
  <div class="r-icon d-flex align-center">
    <img v-if="icon" :src="iconSource">
  </div>
</template>
<script>
export default {
  name: 'RenedianIcon',
  props: ['icon'],
  data: () => ({
    iconSource: '',
    icons: {
      'guests': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FHOW%20MANY%20Guest%201%20or%202%20-%20red.svg?alt=media&token=4287ce39-5646-42ab-9f3a-65e5af1aa37c',
      'guests-solo': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FHOW%20MANY%20-%201%20Guest-%20red.svg?alt=media&token=8a2d5e23-5992-47ca-976c-1743109fe442',
      'guests-couple': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FHOW%20MANY%20-%202%20Guests-%20red.svg?alt=media&token=019d3801-1616-442a-9224-010f4b5c08b5',

      'bed-red': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fbed-red.svg?alt=media&token=64df9be0-5300-43c9-b503-a97d2645ffc6',
      'bed-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fbed-unselected.svg?alt=media&token=4637b415-6ca4-4a9e-abfc-228661acec8d',
      
      'solo-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fsolo-grey.svg?alt=media&token=d3a3025e-44ad-4693-8bf9-a418d6257462',
      'solo-red': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FHOW%20MANY%20-%201%20Guest-%20red.svg?alt=media&token=8a2d5e23-5992-47ca-976c-1743109fe442',

      'passenger-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fpassenger-grey.svg?alt=media&token=bd88bf6c-d89a-43c7-abc7-19b3ad0c1565',
      'passenger-red': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fpassenger-red.svg?alt=media&token=ec15cce5-bce6-43dc-bb31-06bcd6f98d8f',

      'sharing-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fsharing-grey.svg?alt=media&token=3d70cd1e-0bae-407b-9671-798482a913e8',

      'bike-red': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fbike-red.svg?alt=media&token=d154f0b0-ac09-4860-9081-df6cd443d8db',
      'bike-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FWHAT%20BIKE%20-%20dark.svg?alt=media&token=f58c2f78-30bc-4782-9348-9deb8020ac27',

      'excursion-red': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fexcursion-red.svg?alt=media&token=1c7124ea-96e5-4b63-8159-1091ddbce47b',
      'excursion-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fexcursion-grey.svg?alt=media&token=a1b4d239-814f-42ec-b018-1fbd16d43796',

      'van-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FHOW%20-%20By%20Tour%20Van.svg?alt=media&token=1585cf51-9fcb-49b8-a696-635ff31ca97e',
      'solo-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Frider-grey.svg?alt=media&token=124e517f-1fd6-4342-8035-e77ace5c9334',

      'calendar-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fcalendar-grey.svg?alt=media&token=3b19960c-9531-4f8e-b6fa-c7d428babf84',
      'globe-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fglobe-grey.svg?alt=media&token=0839deca-06c7-427f-88f8-0bf8398b0c1f',
      'cancel-grey': 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fcancel-grey.svg?alt=media&token=def6ed7a-d127-47a1-b288-48aa7f496c90'
    }
  }),
  created() {
    // // Access the content inside <r-icon>
    // if (this.$slots.default && this.$slots.default[0]) {
    //   let content = this.$slots.default[0].text;
    //   // Find the icon in the icons object
    //   this.iconSource = this.icons[content];
    // }
    this.iconSource = this.icons[this.icon];
  },
  watch: {
    icon: function (newVal, oldVal) {
      if (newVal) {
        this.iconSource = this.icons[newVal];
      }
    }
  }
}
</script>
<style lang="scss">
  .r-icon {
    width: 25px;
    min-width: 25px;
    height: 18px;
    min-height: 18px;
    img {
      width: 100%;
      height: 100%;
    }
  }
</style>