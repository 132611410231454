<template>
  <v-card>
    <v-progress-linear v-if="loading" color="orange" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-account</v-icon>
      Customers
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-alert type="info">
        <strong>List of all customers</strong>
        <ul>
          <li>Searchable</li>
          <li>Sortable</li>
          <li>
            Information
            <ul>
              <li>Full names</li>
              <li>Email</li>
              <li>Phone</li>
              <li>DOB</li>
              <li>PassPort Number</li>
              <li>Address</li>
              <li>Shipping Adress if different from above</li>
              <li>Billing address if different from above</li>
              <li>T-shirt size</li>
              <li>Past Renedian Tour</li>
              <li>Dietary Requirements</li>
              <li>Own bikes in garage</li>
              <li>*Occupataion / Past occupation (not compulsory)</li>
              <li>*Hobbies / interests (not compulsory)</li>
              <li>*Emergency contact</li>
              <li>*Insurance policy information</li>
            </ul>
          </li>
          <li>
            Fields displayed in the table (limited due to screen space):
            <ul>
              <li>Customer name</li>
              <li>Payment status</li>
              <li>Tour departure date (if applicable)</li>
              <li>Todo list status (if applicable)</li>
              <li>Action list status (if applicable)</li>
            </ul>
          </li>
        </ul>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdminCustomers',
  data: () => ({
    loading: false
  }),
  created() {
    
  },
  methods: {
    
  },
}
</script>
