<template>
  <v-app>
    <v-app-bar app v-if="!isDesktop">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img class="logo-sm ml-3" src="~@/assets/images/renedian-logo.svg" @click="go('Landing')">
      <v-spacer></v-spacer>
      <v-btn icon @click="go('PortalProfile')">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-if="user"
      v-model="drawer"
      app
      :permanent="isDesktop"
      :temporary="!isDesktop"
      class="portal-navigation"
      width="300"
    >
      <v-list>
        <div class="d-flex justify-center pb-2 mt-2">
          <img class="logo ml-2" src="~@/assets/images/renedian-logo.svg" @click="go('Landing')">
        </div>
        <v-list-item-content class="d-flex flex-column align-center mb-2">
          <div class="g-font font-size-14">Rider Portal</div>
          <div class="text-caption orange--text text--darken-3">{{ user.profile.email }}</div>
          <!-- <v-list-item-title v-if="user.profile.name">{{ user.profile.name }}</v-list-item-title>
          <v-list-item-title v-else>Rider Portal</v-list-item-title>
          <div class="text-caption orange--text text--darken-3">{{ user.profile.email }}</div> -->
        </v-list-item-content>
        <v-divider></v-divider>
        <div v-for="(item, index) in menu" :key="index">
          <v-divider v-if="item.divider" :key="index"></v-divider>
          <v-list-item :key="item.title" link @click="go(item.route)" class="ml-2 pl-2" :class="isActive(item)">
            <v-list-item-icon>
              <v-icon :color="isActive(item) ? 'orange darken-3' : 'brown lighten-2'">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-icon v-if="item.route == $route.name">mdi-chevron-right</v-icon>
          </v-list-item>
          <div v-if="item.children.length">
            <v-list-item v-for="(child, childIndex) in item.children" :key="child.id" @click="go(child.route, {index: childIndex})" link class="ml-4" :class="isActiveChild(childIndex)">
              <v-list-item-icon>
                <v-icon :color="isActiveChild(childIndex) ? 'orange darken-3' : 'brown lighten-2'">{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content twoline>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
                <v-list-item-subtitle v-if="child.subtitle">{{ child.subtitle | formatDate }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block text @click="logout">Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="portal-content pa-5">
        <router-view :user="user" :bookings="bookings"></router-view>
        <div class="text-caption text-center mt-4 grey--text text--darken-1">&copy; {{ new Date().getFullYear() }} Renedian Rider Portal (v{{ version }} {{ codename }})</div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { getFirestore, getDocs, collection, where, updateDoc, query as firestoreQuery, onSnapshot } from 'firebase/firestore'
import { DateTime } from 'luxon'
export default {
  name: 'PortalTemplate',
  props: ['user'],
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    codename: process.env.VUE_APP_CODENAME,
    drawer: false,
    isDesktop: window.innerWidth >= 1024, // Adjust this breakpoint as needed
    menu: [
      // --- Dashboard ---
      { title: 'My Tours', icon: 'mdi-view-dashboard', route: 'PortalDashboard', children: [
        // { title: 'Scotland - The Highlands', icon: 'mdi-map-outline', subtitle: 'October 12, 2024', route: 'PortalTour', id: '1' },
        // { title: 'Waterfalls & Wildlife',    icon: 'mdi-map-outline', subtitle: 'October 12, 2025', route: 'PortalTour', id: '2' },
      ]},
      // --- Profile ---
      { title: 'Profile', icon: 'mdi-account-box', route: 'PortalProfile', children: [] },
      // --- Billing ---
      { title: 'Account & Billing', icon: 'mdi-receipt-text-outline', route: 'PortalBilling', children: [] },
    ],
    // ------------ Data ------------
    bookings: [],
    bookingsListener: null
  }),
  created() {
    window.addEventListener('resize', this.handleResize);
    // 
    if (this.bookings.length == 0 && this.user) this.getBookings()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async getBookings() {
      // ---------------------- Fetch Bookings ----------------------
      let bookings = []
      const db = getFirestore();
      const bookingsQuery = firestoreQuery(collection(db, 'bookings'), where('emails', 'array-contains', this.user.profile.email));
      const querySnapshot = await getDocs(bookingsQuery);
      querySnapshot.forEach((doc) => {
        let booking = doc.data();
        booking.id = doc.id
        bookings.push(booking)
      });
      this.bookings = bookings;
      // ------------ Update Menu ------------
      this.menu[0].children = []
      this.bookings.forEach(booking => {
        // Only add the booking if the finish date has not passed already
        if (DateTime.fromISO(booking.schedule.finish) > DateTime.now()) {
          this.menu[0].children.push({ title: booking.tour.name, icon: 'mdi-map-outline', subtitle: booking.schedule.start, route: 'PortalTour', id: booking.id })
        }
      });
    },
    isActive(item) {
      return item.route == this.$route.name ? 'active-item' : '';
    },
    isActiveChild(index) {
      return this.$route.params?.index === index ? 'active-item' : '';
    },
    handleResize() {
      this.isDesktop = window.innerWidth >= 1024; // Adjust this breakpoint as needed
    },
    logout() {
      this.$emit('logout');
    }
  },
  watch: {
    user() {
      // ------------ Bookings Listener ------------
      const db = getFirestore();
      const bookingsQuery = firestoreQuery(collection(db, 'bookings'), where('emails', 'array-contains', this.user.profile.email));
      this.bookingsListener = onSnapshot(bookingsQuery, (snapshot) => {
        console.log('Bookings updated');
        if (this.user) this.getBookings();
      });
      //
      this.getBookings()
    },
    $route(to, from) {
      
    }
  },
  filters: {
    formatDate(value) {
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy')
    }, 
  }
}
</script>

<style scoped lang="scss">
.portal-navigation {
  background: #F4F0E3;
}
.logo {
  max-height: 70px;
}
.logo-sm {
  max-height: 38px;
}
.portal-content {
  background: #f3f3f3;
  background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ftopographic-5.png?alt=media&token=a5587c86-c9e5-4023-9e16-e3f27aa4956c');
  overflow: scroll;
  height: 100%;
  width: 100%;
}
.active {
  /* background: #ffffff77 !important; */
}
.v-list-group--active {
  background: #e96800 !important;
}
.v-list-item--active {
  color: white !important;
}
.v-list-group__header {
  color: white !important;
  .v-list-group__header__prepend-icon {
    color: white !important;
  }
}
.active-item {
  background: white;
  color: #e65100 !important;
}
</style>