<template>
  <v-app>
    <component :is="currentTemplate" :user="user" @save="saveUser()" @logout="logout()"></component>
  </v-app>
</template>
<script>
import { getAuth, onAuthStateChanged, sendEmailVerification, getAdditionalUserInfo, signOut } from 'firebase/auth'
import { getFirestore, doc, setDoc, onSnapshot } from 'firebase/firestore'
// import { environment } from '@/firebase/init.js'
import AdminTemplate from '/src/views/templates/Admin.vue'
import ExperimentalTemplate from '/src/views/templates/Experimental.vue'
import MockupTemplate from '/src/views/templates/Mockup.vue'
import PortalTemplate from '/src/views/templates/Portal.vue'
import StandardTemplate from '/src/views/templates/Standard.vue'
export default {
  name: 'App',
  components: {
    AdminTemplate, ExperimentalTemplate, MockupTemplate, PortalTemplate, StandardTemplate
  },
  data: () => ({
    user: false,
    userListener: null,
  }),
  created() {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // Fetch user account
        const db = getFirestore()
        const userRef = doc(db, 'users', user.uid)
        this.userListener = onSnapshot(userRef, doc => {
          console.log('delta')
          if (doc.data()) {
            // ------------ Existing User ------------
            this.user = doc.data()
            console.log('welcome back', doc.data())
            // ------ Check Verification Status ------
            // Check if user email verification status is aligned with Firebase
            // if (this.user.profile.verified != user.emailVerified) {
            //   this.user.profile.verified = user.emailVerified
            //   this.saveUser()
            // }
          } else {
            // ------------ New User ------------
            console.log('create new user')
            setDoc(userRef, {
              uid: user.uid,
              profile: {
                name: user.displayName,
                email: user.email,
                avatar: user.photoURL,
                verified: user.emailVerified,
                phone: {
                  number: user.phoneNumber,
                  verified: false
                },
                registered: Date.now(),
              }
            }).then(() => {
              // if (!user.emailVerified) sendEmailVerification(user)
              this.go('PortalDashboard')
            }).catch(() => {
              console.log('User data could not be saved')
              this.logout()
            })
          }
        })
      } else {
        // ------------ No User ------------
        // Note that this will be called under two conditions:
        // - delta: the user logs out
        // - load: ON THE FIRST PAGE LOAD when the user is not logged in
        // this.user = false
        // this.go('PortalLogin')
        // this.logout()
      }
    })
  },
  methods: {
    saveUser() {
      const auth = getAuth()
      const db = getFirestore()
      const userRef = doc(db, 'users', auth.currentUser.uid)
      setDoc(userRef, this.user).then(() => {
        console.log('User data saved')
      }).catch(() => {
        console.log('User data could not be saved')
      })
    },
    logout() {
      if (this.userListener != null) this.userListener() // Unsubscribe from the user listener
      const auth = getAuth()
      signOut(auth).then(() => { // Sign-out successful.
        this.user = false
        this.go('PortalLogin')
      }).catch((error) => { // An error happened.
        console.log('Something went wrong. Could not sign out.')
      });
    },
  },
  computed: {
    currentTemplate() {
      return (this.$route.meta.template || 'Standard') + 'Template';
    },
  },
}
</script>
<style>
  .width-100 {
    width: 100%;
  }
  .height-100 {
    height: 100%;
  }
  .flex-none {
    flex: none !important;
  }
  .flex-1 {
    flex: 1 !important;
  }
  .flex-2 {
    flex: 2 !important;
  }
  .flex-3 {
    flex: 3 !important;
  }
  .v-card__actions {
    background: #f3f3f3 !important;
  }
  /* Renedian Colour Palette */
  .renedian-beige--text {
    color: #f4f0e3 !important;
  }
  .renedian-dark-beige--text {
    color: #e9debd !important;
  }
  .renedian-dark-earth--text {
    color: #c9b295 !important;
  }
  .renedian-bright-orange--text {
    color: #f58752 !important;
  }
  .renedian-burnt-orange--text {
    color: #d86226 !important;
  }
  .renedian-red--text {
    color: #bd4426 !important;
  }
  .renedian-grey--text {
    color: #666666 !important;
  }
  .renedian-beige {
    background-color: #f4f0e3;
  }
  .renedian-dark-beige {
    background-color: #e9debd;
  }
  .renedian-dark-earth {
    background-color: #c9b295;
  }
  .renedian-burnt-orange {
    background-color: #d86226;
  }
  .renedian-red {
    background-color: #bd4426;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('~@/assets/fonts/gotham-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Renedian';
    src: url('~@/assets/fonts/renedian-font.woff2') format('woff2'),
        url('~@/assets/fonts/renedian-font.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url('~@/assets/fonts/lato-regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato-Bold';
    src: url('~@/assets/fonts/lato-bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato-Italic';
    src: url('~@/assets/fonts/lato-italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
  }
  .g-font {
    font-family: 'Gotham', sans-serif;
    letter-spacing: 1px;
    font-size: 17px;
    text-transform: uppercase;
  }
  .r-font {
    font-family: 'Renedian', sans-serif;
    letter-spacing: 1px;
    font-size: 17px;
    text-transform: uppercase;
  }
  .font-lato {
    font-family: 'Lato', sans-serif;
  }
  .font-lato-bold {
    font-family: 'Lato-Bold', sans-serif;
    font-weight: bold;
  }
  .font-lato-italic {
    font-family: 'Lato-Italic', sans-serif;
    font-style: italic;
  }
  .r-title {
    font-family: 'Renedian', sans-serif;
    color: #bd4426;
    font-size: 30px;
  }
</style>