<template>
  <v-card>
    <v-progress-linear v-if="isPageLoading" color="primary" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-motorbike</v-icon>
      Motorcycles
      <v-spacer></v-spacer>
      <v-btn @click="openCreateVehicleProviderDialog" dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Vehicle Provider</v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert type="success" v-if="pageSuccessMessage" dismissible>{{ pageSuccessMessage }}</v-alert>
      <v-alert type="error" v-if="pageErrorMessage" dismissible>{{ pageErrorMessage }}</v-alert>
      <!-- Providers -->
      <div v-if="vehicleProviders.length" v-for="(provider, providerIndex) in vehicleProviders" :key="'vehicleProvider-' + providerIndex" class="provider-container mb-4">
        <!-- Provider Header -->
        <div class="d-flex flex-row align-center provider-container-header">
          <v-avatar v-if="provider.logo" color="primary" class="mr-2" dark rounded size="48">
            <img :src="provider.logo" />
          </v-avatar>
          <v-avatar v-else color="grey lighten-3" class="mr-2" rounded size="48">{{ provider.name | firstTwo }}</v-avatar>
          <div class="font-size-20">{{ provider.name || 'Unnamed Provider' }} ({{ provider.continent || 'Unknown Continent' }})</div>
          <v-spacer></v-spacer>
          <v-btn @click="openCreateVehicleDialog(provider.id)" dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Vehicle</v-btn>
          <v-menu offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="ma-1">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openEditVehicleProviderDialog(provider)" link>
                <v-icon left>mdi-pencil</v-icon>
                Edit Provider
              </v-list-item>
              <v-list-item @click="confirmDeleteProvider(provider.id)" link>
                <v-icon left>mdi-delete</v-icon>
                Delete Provider
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <!-- Provider Vehicles -->
        <v-row no-gutters>
          <v-col v-for="(vehicle, index) in provider.vehicles" :key="'vehicle-' + providerIndex + '-' + index" xs="12" sm="6" md="4" lg="3" :style="{'order': vehicle.order}">
            <v-card class="ma-2">
              <v-img contain :src="vehicle.images.thumbnail || ''" class="text-right" height="218" :style="{'background': vehicle.images.thumbnail ? 'white' : '#efefef'}">
                <!-- Vertical Menu -->
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="ma-1">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="openEditVehicleDialog(vehicle, providerIndex)" link>
                      <v-icon left>mdi-pencil</v-icon>
                      Edit Vehicle
                    </v-list-item>
                    <v-list-item @click="confirmDeleteVehicle(vehicle, providerIndex)" link>
                      <v-icon left>mdi-delete</v-icon>
                      Delete Vehicle
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-img>
              <v-divider></v-divider>
              <v-card-title class="pb-3">
                {{ vehicle.make || 'Unknown Make' }} {{ vehicle.model || 'Unknown Model' }}
              </v-card-title>
              <v-card-text class="d-flex flex-row align-center">
                <div class="green--text flex-1">
                  {{ vehicle.price ? formatCurrency(vehicle.price) : 'N/A' }}
                </div>
                <v-chip v-if="vehicle.options && vehicle.options.length" class="mx-1" outlined small>Options</v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <!-- Vehicle Dialog -->
    <v-dialog v-if="currentVehicle" v-model="vehicleDialog" width="800">
      <v-card>
        <v-card-title>
          {{ currentVehicle.id ? 'Edit Vehicle' : 'New Vehicle' }}
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-row">
            <div class="flex-1">
              <v-text-field label="Make" v-model="currentVehicle.make"></v-text-field>
              <v-text-field label="Model" v-model="currentVehicle.model"></v-text-field>
              <v-textarea label="Description" v-model="currentVehicle.description" rows="2"></v-textarea>
              <v-select
                v-model="currentVehicle.providerId"
                :items="providerOptions"
                label="Provider"
                item-text="name"
                item-value="id"
              ></v-select>
              <v-text-field 
                label="Thumbnail URL" 
                v-model="currentVehicle.images.thumbnail" 
                prepend-icon="mdi-attachment" 
                @click:prepend="triggerFileUpload('thumbnail')"
              >
                <template v-slot:progress>
                  <v-progress-linear
                    v-if="isImageUploading"
                    :value="uploadProgress"
                    color="primary"
                    height="7"
                  ></v-progress-linear>
                </template>
              </v-text-field>
              <input type="file" ref="thumbnailInput" style="display: none" @change="uploadImage('thumbnail', $event)">
              <v-text-field label="Rental Cost" v-model="currentVehicle.rate.cost" :prefix="currentVehicle.rate.currency + '$'" suffix="daily"></v-text-field>
              <v-text-field label="Fuel Capacity" v-model="currentVehicle.fuel.capacity" suffix="L"></v-text-field>
              <v-text-field label="Fuel Range" v-model="currentVehicle.fuel.range" suffix="km"></v-text-field>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div class="flex-1">
              <div class="overline">Images</div>
              <div class="d-flex images">
                <v-img v-if="currentVehicle.images.thumbnail" :src="currentVehicle.images.thumbnail" contain height="100" width="100"></v-img>
              </div>
              <div class="d-flex flex-row mb-2 align-center">
                <div class="text-overline flex-1">Options</div>
                <v-btn text @click="openOptionDialog"><v-icon left>mdi-plus</v-icon>Add Option</v-btn>
              </div>
              <div v-for="(option, index) in currentVehicle.options" :key="'option-list-' + index">
                <div class="d-flex flex-row align-center">
                  <div class="subtitle flex-3">{{ index + 1 }}. {{ option.name }}</div>
                  <div class="subtitle flex-1 green--text">{{ formatCurrency(option.price) }}</div>
                  <v-btn small icon @click="removeOption(index)"><v-icon small>mdi-delete</v-icon></v-btn>
                  <v-btn small icon @click="openOptionDialog(index)"><v-icon small>mdi-pencil</v-icon></v-btn>
                </div>
              </div>
              <div v-if="!currentVehicle.options || currentVehicle.options.length === 0" class="grey--text">No options provided.</div>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="secondary" text @click="vehicleDialog = false" :disabled="isVehicleSaving">
            <v-icon left>mdi-close</v-icon>Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="currentVehicle.id" color="primary" dark @click="saveVehicle" :loading="isVehicleSaving">
            <v-icon left>mdi-content-save-outline</v-icon>Update Vehicle
          </v-btn>
          <v-btn v-else color="primary" dark @click="saveVehicle" :loading="isVehicleSaving">
            <v-icon left>mdi-content-save-outline</v-icon>Save Vehicle
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Provider Confirmation Dialog -->
    <v-dialog v-model="confirmDeleteProviderDialog" width="400">
      <v-card>
        <v-card-title class="headline">Delete Provider</v-card-title>
        <v-card-text>
          <v-alert type="error">
            This action is permanent and cannot be undone.
          </v-alert>
          <v-checkbox v-model="providerDeletionConfirmed" color="red" label="I understand the consequences"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="confirmDeleteProviderDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text :disabled="!providerDeletionConfirmed" @click="deleteProvider(providerToDelete)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Vehicle Dialog -->
    <v-dialog v-model="deleteVehicleDialog" width="400">
      <v-card>
        <v-card-title class="headline">Delete Vehicle</v-card-title>
        <v-card-text>
          <v-alert type="error">
            This action is permanent and cannot be undone. 
          </v-alert>
          <v-checkbox v-model="vehicleDeletionConfirmed" color="red" label="I understand the consequences"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="deleteVehicleDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text :disabled="!vehicleDeletionConfirmed" @click="deleteVehicle">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Option Dialog -->
    <v-dialog v-model="optionDialog" width="400">
      <v-card>
        <v-card-title>
          Vehicle Option
          <v-spacer></v-spacer>
          <v-btn icon @click="optionDialog=false"><v-icon>mdi-close</v-icon></v-btn>  
        </v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="option.name"></v-text-field>
          <v-text-field label="Price" v-model="option.price" prefix="C$"></v-text-field>
          <v-text-field label="Cost" v-model="option.cost" prefix="C$" suffix="per diem"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="optionDialog=false" color="primary" text>Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Vehicle Provider Dialog -->
    <v-dialog v-model="vehicleProviderDialog" width="400">
      <v-card>
        <v-card-title>
          {{ currentVehicleProvider && currentVehicleProvider.id ? 'Edit Vehicle Provider' : 'New Vehicle Provider' }}
          <v-spacer></v-spacer>
          <v-btn icon @click="vehicleProviderDialog=false"><v-icon>mdi-close</v-icon></v-btn>  
        </v-card-title>
        <v-card-text v-if="currentVehicleProvider">
          <div class="d-flex flex-row">
            <div class="flex-1">
              <v-text-field label="Provider Name" v-model="currentVehicleProvider.name"></v-text-field>
              <v-select
                v-model="currentVehicleProvider.continent"
                :items="continents"
                label="Continent"
              ></v-select>
              <v-text-field label="Contact" v-model="currentVehicleProvider.contact"></v-text-field>
              <v-text-field label="Email" v-model="currentVehicleProvider.email"></v-text-field>
              <v-text-field label="Phone" v-model="currentVehicleProvider.phone"></v-text-field>
              <v-text-field label="Address" v-model="currentVehicleProvider.address"></v-text-field>
              <v-text-field 
                label="Logo URL" 
                v-model="currentVehicleProvider.logo" 
                prepend-icon="mdi-attachment" 
                @click:prepend="triggerFileUpload('logo')"
              >
                <template v-slot:progress>
                  <v-progress-linear
                    v-if="isImageUploading"
                    :value="uploadProgress"
                    color="primary"
                    height="7"
                  ></v-progress-linear>
                </template>
              </v-text-field>
              <input type="file" ref="logoInput" style="display: none" @change="uploadProviderImage('logo', $event)">
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="vehicleProviderDialog=false" color="primary" text>Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveVehicleProvider" color="primary" text>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getFirestore, doc, addDoc, setDoc, query, orderBy, onSnapshot, collection, deleteDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

export default {
  name: 'AdminVehicleProvidersVehicles',
  data: () => ({
    // Page states
    isPageLoading: false,
    isVehicleSaving: false,
    isImageUploading: false,
    uploadProgress: 0,
    // Messages
    pageSuccessMessage: '',
    pageErrorMessage: '',
    // Dialog states
    vehicleDialog: false,
    vehicleProviderDialog: false,
    deleteVehicleDialog: false,
    confirmDeleteProviderDialog: false,
    optionDialog: false,
    // Flags
    vehicleDeletionConfirmed: false,
    providerDeletionConfirmed: false,
    // Current data
    currentVehicle: null,
    currentVehicleProvider: null,
    providerToDelete: null,
    // Collections
    vehiclesCollection: 'vehicles',
    vehicleProvidersCollection: 'vehicleProviders',
    // Data arrays
    vehicleProviders: [],
    unknownProviderVehicles: [],
    // Form options
    continents: [
      'Africa',
      'Antarctica',
      'Asia',
      'Oceania',
      'Europe',
      'North America',
      'South America',
    ],
    // Default models
    defaultVehicle: {
      id: null,
      make: '',
      model: '',
      description: '',
      rate: {
        cost: 0,
        supplement: 0,
        currency: 'CAD'
      },
      fuel: {
        capacity: 0,
        range: 0,
        units: 'Metric'
      },
      images: {
        thumbnail: ''
      },
      options: [],
      order: 0,
      providerId: null
    },
    newVehicleProvider: {
      name: '',
      continent: '',
      vehicles: [],
      contact: '',
      email: '',
      phone: '',
      address: '',
      logo: ''
    },
    option: {
      name: '',
      price: 0,
      cost: 0,
    }
  }),
  computed: {
    providerOptions() {
      return this.vehicleProviders.map(provider => ({
        id: provider.id,
        name: provider.name || 'Unnamed Provider'
      }));
    }
  },
  created() {
    const db = getFirestore()
    const vehicleProvidersRef = collection(db, this.vehicleProvidersCollection)
    const vehiclesRef = collection(db, this.vehiclesCollection)
    
    const orderedVehicleProvidersRef = query(vehicleProvidersRef, orderBy('name'))
    onSnapshot(orderedVehicleProvidersRef, (snapshot) => {
      this.isPageLoading = true
      let providers = []
      snapshot.forEach((doc) => {
        let provider = doc.data()
        provider.id = doc.id
        provider.vehicles = []
        providers.push(provider)
      })
      this.vehicleProviders = providers
      this.isPageLoading = false
    })
    
    onSnapshot(vehiclesRef, (snapshot) => {
      let vehicles = []
      snapshot.forEach((doc) => {
        let vehicle = doc.data()
        vehicle.id = doc.id
        vehicles.push(vehicle)
      })
      this.unknownProviderVehicles = vehicles.filter(v => !v.providerId)
      this.vehicleProviders.forEach(provider => {
        provider.vehicles = vehicles.filter(v => v.providerId === provider.id)
      })
    })
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD'
      }).format(value);
    },
    openCreateVehicleProviderDialog() {
      this.currentVehicleProvider = { ...this.newVehicleProvider };
      this.vehicleProviderDialog = true;
    },
    openEditVehicleProviderDialog(provider) {
      this.currentVehicleProvider = { ...provider };
      this.vehicleProviderDialog = true;
    },
    saveVehicleProvider() {
      const db = getFirestore();
      const providerData = { ...this.currentVehicleProvider };
      if (providerData.id) {
        setDoc(doc(db, this.vehicleProvidersCollection, providerData.id), providerData)
          .then(() => {
            this.pageSuccessMessage = 'Vehicle provider updated successfully';
            this.vehicleProviderDialog = false;
          })
          .catch(error => {
            this.pageErrorMessage = 'Failed to update vehicle provider: ' + error.message;
          });
      } else {
        addDoc(collection(db, this.vehicleProvidersCollection), providerData)
          .then(() => {
            this.pageSuccessMessage = 'Vehicle provider added successfully';
            this.vehicleProviderDialog = false;
          })
          .catch(error => {
            this.pageErrorMessage = 'Failed to add vehicle provider: ' + error.message;
          });
      }
    },
    confirmDeleteProvider(providerId) {
      this.providerToDelete = providerId;
      this.confirmDeleteProviderDialog = true;
    },
    deleteProvider() {
      const db = getFirestore();
      deleteDoc(doc(db, this.vehicleProvidersCollection, this.providerToDelete))
        .then(() => {
          this.pageSuccessMessage = 'Vehicle provider deleted successfully';
          this.confirmDeleteProviderDialog = false;
        })
        .catch(error => {
          this.pageErrorMessage = 'Failed to delete vehicle provider: ' + error.message;
        });
    },
    openCreateVehicleDialog(providerId) {
      this.currentVehicle = { ...this.defaultVehicle, providerId };
      this.vehicleDialog = true;
    },
    openEditVehicleDialog(vehicle) {
      this.currentVehicle = { ...vehicle };
      this.vehicleDialog = true;
    },
    saveVehicle() {
      const db = getFirestore();
      const vehicleData = { ...this.currentVehicle };
      if (vehicleData.id) {
        setDoc(doc(db, this.vehiclesCollection, vehicleData.id), vehicleData)
          .then(() => {
            this.pageSuccessMessage = 'Vehicle updated successfully';
            this.vehicleDialog = false;
          })
          .catch(error => {
            this.pageErrorMessage = 'Failed to update vehicle: ' + error.message;
          });
      } else {
        addDoc(collection(db, this.vehiclesCollection), vehicleData)
          .then(() => {
            this.pageSuccessMessage = 'Vehicle added successfully';
            this.vehicleDialog = false;
          })
          .catch(error => {
            this.pageErrorMessage = 'Failed to add vehicle: ' + error.message;
          });
      }
    },
    confirmDeleteVehicle(vehicle) {
      this.currentVehicle = { ...vehicle };
      this.deleteVehicleDialog = true;
    },
    deleteVehicle() {
      const db = getFirestore();
      deleteDoc(doc(db, this.vehiclesCollection, this.currentVehicle.id))
        .then(() => {
          this.pageSuccessMessage = 'Vehicle deleted successfully';
          this.deleteVehicleDialog = false;
        })
        .catch(error => {
          this.pageErrorMessage = 'Failed to delete vehicle: ' + error.message;
        });
    },
    uploadImage(type, event) {
      const file = event.target.files[0];
      const storage = getStorage();
      const storageRef = ref(storage, 'images/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed', snapshot => {
        this.isImageUploading = true;
        this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      }, error => {
        this.pageErrorMessage = 'Failed to upload image: ' + error.message;
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          if (type === 'thumbnail') {
            this.currentVehicle.images.thumbnail = downloadURL;
          } else if (type === 'logo') {
            this.currentVehicleProvider.logo = downloadURL;
          }
          this.isImageUploading = false;
        });
      });
    },
    triggerFileUpload(type) {
      if (type === 'thumbnail') {
        this.$refs.thumbnailInput.click();
      } else if (type === 'logo') {
        this.$refs.logoInput.click();
      }
    },
    uploadProviderImage(type, event) {
      this.uploadImage(type, event);
    },
    openOptionDialog(index = null) {
      if (index !== null) {
        this.option = { ...this.currentVehicle.options[index] };
        this.optionDialog = true;
      } else {
        this.option = { name: '', price: 0, cost: 0 };
        this.optionDialog = true;
      }
    },
    removeOption(index) {
      this.currentVehicle.options.splice(index, 1);
    }
  },
  filters: {
    firstTwo(value) {
      return value.substring(0, 2).toUpperCase();
    }
  }
}
</script>

<style scoped>
.provider-container {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 5px;
}
.provider-container-header {
  margin-bottom: 1rem;
}
.images {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
</style>