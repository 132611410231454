<template>
  <v-card>
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-binoculars</v-icon>
      Activities
      <v-spacer></v-spacer>
      <v-btn @click="openActivity('new')" dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Activity</v-btn>
    </v-card-title>
    <v-card-text v-if="!loading" class="mb-4">
      <div v-if="activities.length">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search Activities"
          single-line
          hide-details
          filled
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="filteredActivities"
          :items-per-page="20"
          class="elevation-1"
          @click:row="openActivity"
        >
          <!-- Override -->
          <!-- <template v-slot:item.basis="{ item }"> -->
            <!-- {{ computeBasis(item) }} -->
          <!-- </template> -->
        </v-data-table>
      </div>
      <div v-else class="mb-4">
        <v-alert type="info" outlined>There are no activities configured</v-alert>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { getFirestore, onSnapshot, collection } from 'firebase/firestore'
export default {
  name: 'AdminVendorsActivities',
  data: () => ({
    loading: true,
    // Data Table
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'type' },
      { text: 'Country', value: 'location.country' },
      { text: 'Continent', value: 'location.continent' },
    ],
    // 
    activities: [],
  }),
  created() {
    const db = getFirestore()
    const collectionRef = collection(db, 'activities')
    onSnapshot(collectionRef, (snapshot) => {
      this.loading = true
      let items = []
      snapshot.forEach((doc) => {
        let item = doc.data()
        item.id = doc.id
        items.push(item)
      })
      this.activities = items
      this.loading = false
    })
  },
  methods: {
    openActivity(item) {
      if (item.id) {
        this.go('AdminVendorsActivity', {'id': item.id})
      } else {
        this.go('AdminVendorsActivity', {'id': 'new'})
      }
    },
  },
  computed: {
    filteredActivities() {
      if (!this.search) {
        return this.activities
      }
      const searchLower = this.search.toLowerCase()
      return this.activities.filter(activity => {
        const name = activity.name.toLowerCase()
        const type = activity.type.toLowerCase()
        const country = activity.location.country.toLowerCase()
        const continent = activity.location.continent.toLowerCase()
        
        return (
          name.includes(searchLower) ||
          type.includes(searchLower) ||
          country.includes(searchLower) ||
          continent.includes(searchLower)
        )
      })
    }
  },
}
</script>
