<template>
  <v-container>
    <div class="g-font renedian-red--text mb-6 font-size-22">Profile</div>
    <v-tabs v-model="tab">
      <v-tab>Personal</v-tab>
      <!-- <v-tab>Alumni</v-tab> -->
    </v-tabs>
    <v-sheet class="pa-6">
      <v-tabs-items v-model="tab">
        <!-- Personal -->
        <v-tab-item>

          <v-card class="ma-6">
            <v-card-text>
              - Name<br>
              - Address<br>
              - Phone<br>
              - Etc.<br>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Alumni -->
        <!-- <v-tab-item>
          <v-card class="ma-6">
            <v-card-text>
              - Alumni Discount Program<br>
              - Previous Tours<br>
              - Certificates<br>
            </v-card-text>
          </v-card>
        </v-tab-item> -->
      </v-tabs-items>
    </v-sheet>
  </v-container>
</template>
<script>
export default {
  name: 'PortalProfile',
  props: ['user'],
  data: () => ({
    tab: 0,
  }),
  methods: {
    
  }
}
</script>