<template>
  <v-container>
    <!-- <div class="g-font renedian-red--text mb-6 font-size-22">Tour</div> -->
    <v-progress-linear v-if="loading" indeterminate color="amber"></v-progress-linear>
    <v-img
      v-if="tour"
      class="d-flex align-center white--text rounded-image"
      height="180"
      :src="tour.template.images.banner"
    >
      <div class="d-flex flex-column justify-center pa-6">
        <div class="text-h3 white--text font-weight-bold text-shadow">
          {{ tour.template.details.name }}
        </div>
        <div class="font-size-16 white--text">
          {{ tour.schedule.primary.start | humanDate }} - {{ tour.schedule.primary.finish | humanDate }}
        </div>
      </div>
    </v-img>
    <!-- Sub Menu-->
    <v-tabs v-model="tab">
      <v-tab>
        <v-badge color="red" dot>
          Action Items
        </v-badge>
      </v-tab>
      <v-tab>Getting Ready</v-tab>
      <v-tab>Itinerary</v-tab>
      <v-tab>Guides and Crew</v-tab>
      <v-tab>Manage Booking</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" v-if="!loading">
      <!-- ------------ Action Items ------------ -->
      <v-tab-item>
        <h3 class="pa-6 pb-0">Remaining Items</h3>
        <v-expansion-panels v-model="panel" class="pa-6" popout>
          <!-- Personal Information -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Personal Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>T-Shirt Size</li>
                <li>Dietary Requirements</li>
                <li>Medical Needs</li>
                <li>Medical Allergies</li>
                <li>Emergency Contact</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Waiver -->
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              Waiver
              <template v-slot:actions>
                <v-icon color="error">
                  mdi-alert-circle
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>Accept</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Insurance Information -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Insurance Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>Travel Medical Insurance</li>
                <li>Standard Coverage</li>
                <li>VIP Coverage</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Driver's License Information -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Driver's License Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Note: Rider's only
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Passport Information -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Passport Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>Scan</li>
                <li>Details</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Flight Information -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Flight Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>Arrival</li>
                <li>Departure</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Payment -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Payment
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>Deposit</li>
                <li>Balance</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <h3 class="pa-6 pb-0">Completed Items</h3>
        <v-expansion-panels class="pa-6" popout>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate class="completed-panel green--text">
              Personal Information
              <template v-slot:actions>
                <v-icon color="green">
                  mdi-check
                </v-icon>
              </template>
            </v-expansion-panel-header>

          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
      <!-- ------------ Getting Ready ------------ -->
      <v-tab-item>
        <div class="pa-6 d-flex flex-column flex-md-row">
          <v-sheet class="flex-1 mb-6 mb-md-0">
            <v-alert type="info" class="mr-2">
              Checklist, etc.
            </v-alert>
          </v-sheet>
          <v-sheet class="flex-1" color="grey lighten-3" rounded>
            <v-progress-linear v-if="pdfLoading" indeterminate color="primary"></v-progress-linear>
            <embed :src="pdfUrlWithPage" type="application/pdf" width="100%" height="600px" @load="onPdfLoad" />
          </v-sheet>
        </div>
      </v-tab-item>
      <!-- ------------ Itinerary ------------ -->
      <v-tab-item>
        <div class="pa-6 d-flex flex-column flex-md-row">
          <v-sheet class="flex-1 mb-6 mb-md-0">
            <v-expansion-panels popout v-model="itineraryIndex" class="pr-md-6">
              <v-expansion-panel v-for="(day, index) in tour.itinerary" :key="'day'+index">
                <v-expansion-panel-header>
                  Day {{ index + 1 }}: {{ day.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="text-caption font-weight-bold">{{ day.date | fullHumanDate }}</div>
                  <div class="text-caption d-flex align-center"><v-icon>mdi-weather-partly-cloudy</v-icon> High 12° Low 8°</div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-sheet>
          <v-sheet class="flex-1 overflow-hidden" color="grey lighten-3" rounded>
            <v-img :src="tour.itinerary[itineraryIndex]?.image" height="200"></v-img>
            <div v-if="itineraryIndex >= 0" class="pa-6">
              <div class="g-font renedian-red--text font-size-18">{{ tour.itinerary[itineraryIndex].name }}</div>
              <!-- Roads & Weather -->
              <div class="roads-and-weather">
                <div class="daily-weather d-flex align-center mb-1">
                  <v-icon>mdi-weather-partly-cloudy</v-icon> High 12° Low 8°
                </div>
                <div class="daily-roads d-flex align-center">
                  <v-icon>mdi-motorbike</v-icon> 
                  <span v-if="tour.itinerary[itineraryIndex].distance.paved">{{ tour.itinerary[itineraryIndex].distance.paved }} km paved, {{ tour.itinerary[itineraryIndex].distance.gravel }} km gravel</span>
                  <span v-else>No Riding</span>
                </div>
              </div>
              <!-- Description -->
              <p>{{ tour.itinerary[itineraryIndex].description }}</p>              

              <div class="overline">Hotel</div>
              <p>{{ tour.itinerary[itineraryIndex].hotel }}</p>

              <div class="overline">Restaurants</div>
              <p>{{ tour.itinerary[itineraryIndex].restaurants }}</p>

              <div class="overline">Activities</div>
              <p>{{ tour.itinerary[itineraryIndex].activities }}</p>
              
            </div>
          </v-sheet>
        </div>
      </v-tab-item>
      <!-- ------------ Guides & Crew ------------ -->
      <v-tab-item>
        <div class="d-flex flex-row flex-wrap">
          <v-card width="200" height="318" class="ma-4">
            <v-sheet color="orange darken-3" class="badge-top d-flex flex-column align-center">
              <div class="badge-header pt-2 d-flex justify-end mt-3"></div>
            </v-sheet>
            <v-sheet color="white" class="d-flex flex-column align-center">
              <v-avatar class="badge-photo" size="80">
                <v-img src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-placeholder.jpeg?alt=media&token=3ba9a1a3-f661-42d2-bc16-875bbcb9646c" contain></v-img>
              </v-avatar>
              <div class="text-h6 mt-2 grey--text text--darken-2">Lead Guide</div>
              <div class="mt-2 mx-4 justify-center">
                
              </div>
            </v-sheet>
          </v-card>
          <v-card width="200" height="318" class="ma-4">
            <v-sheet color="orange darken-3" class="badge-top d-flex flex-column align-center">
              <div class="badge-header pt-2 d-flex justify-end mt-3"></div>
            </v-sheet>
            <v-sheet color="white" class="d-flex flex-column align-center">
              <v-avatar class="badge-photo" size="80">
                <v-img src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-placeholder.jpeg?alt=media&token=3ba9a1a3-f661-42d2-bc16-875bbcb9646c" contain></v-img>
              </v-avatar>
              <div class="text-h6 mt-2 grey--text text--darken-2">Lead Guide</div>
              <div class="mt-2 mx-4 justify-center">
                
              </div>
            </v-sheet>
          </v-card>
        </div>
      </v-tab-item>
      <!-- Manage Booking -->
      <v-tab-item class="pa-6">
        {{ booking }}
        <v-alert type="info">
          <ul>
            <li>Summary of booking</li>
            <li>vehicle</li>
            <li>accommodation</li>
            <li>dietary</li>
            <li>make a payment?</li>
          </ul>
        </v-alert>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import { getFirestore, onSnapshot, doc } from 'firebase/firestore'
import { DateTime } from 'luxon'
export default {
  name: 'PortalTour',
  props: ['user', 'bookings'],
  data: () => ({
    loading: true,
    tab: 0,
    index: null,
    // ------------ Booking ------------
    booking: null,
    tour: null,
    // ------------ Action Items ------------
    panel: null,
    // ------------ Itinerary ------------
    itineraryIndex: 0,
    // ------------ Getting Ready ------------
    pdfLoading: true,
    pdfUrl: 'https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/publications%2Froadbooks%2Froadbook-example.pdf?alt=media&token=518deaaa-1e60-4f79-b1fc-e00753da63b6',
    pdfUrlWithPage: '',
  }),
  methods: {
    initialize(source) {
      console.log('initialize', source)
      this.loading = true
      // ------------ Parameters ------------
      // Extract the parameters
      this.index = this.$route.params?.index
      this.tab = this.$route.params?.tab
      // ------------ Set Booking ------------
      this.booking = this.index >= 0 ? this.bookings[this.index] : null
      console.log('loading booking', this.index, this.bookings, this.booking)
      // ------------ Load Template ------------
      if (this.booking) {
        console.log('loading booking')
        const db = getFirestore()
        const docRef = doc(db, 'tours', this.booking.tour.id)
        onSnapshot( docRef, (doc) => {
          console.log(doc.data())
          this.tour = doc.data()
          this.tour.id = doc.id
          this.loading = false
        })
      } else {
        this.error = 'Booking not found'
        this.loading = false
      }
      // ------------ Load Roadbook ------------
      this.setPdfPage(2)
    },
    onPdfLoad() {
      this.pdfLoading = false;
    },
    setPdfPage(pageNumber) {
      this.pdfUrlWithPage = `${this.pdfUrl}#page=${pageNumber}`;
      this.pdfLoading = true;
    }
  },
  created() {
    this.initialize(1)
  },
  watch: {
    $route(route) {
      this.initialize(2)
    },
    bookings() {
      this.initialize(3)
    }
  },
  filters: {
    humanDate: function (value) {
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy')
    },
    fullHumanDate: function (value) {
      // Monday, August 23, 2024
      return DateTime.fromISO(value).toFormat('cccc, LLLL d, yyyy')
    },
  }
}
</script>
<style lang="scss" scoped>
  .rounded-image {
    border-radius: 10px 10px 0 0;
  }
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  .completed-panel {
    border: 1px solid #4caf50;
  }
  .badge-top {
    background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fworld-map-160h-10o.png?alt=media&token=c2954802-434c-46b4-b56c-192b3338e849');
    background-size: cover;
    height: 100px;
    .badge-header {
      width: 100%;
      background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-badge-hole-small.png?alt=media&token=a94173a3-40c8-4516-9c1f-53be87f5c250');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .badge-status {
    line-height: 1rem !important;
  }
  .badge-photo {
    margin-top: -40px;
    border: 2px solid white;
  }
  .roads-and-weather {
    background: #f7f7f7;
    padding: 11px;
    border-radius: 5px;
    margin: 10px 0;
  }
</style>