<template>
  <v-sheet>
    Statistics
  </v-sheet>
</template>
<script>
export default {
  name: 'Statistics',
  data: () => ({
    //
  }),
  created() {
    
  }
}
</script>