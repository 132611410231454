<template>
  <div>
    <v-card v-if="model">
      <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
      <v-card-title>
        <v-btn @click="go('AdminVendorsActivities')" icon class="mr-3"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-icon color="orange darken-3" left>mdi-binoculars</v-icon>
        <span>{{ model && model.name ? model.name : "Add Activity" }}</span>
      </v-card-title>
      <v-card-text>
        <v-alert type="success" v-if="success" dismissible>{{ success }}</v-alert>
        <v-alert type="error" v-if="error" dismissible>{{ error }}</v-alert>
        <!-- ------------ Primary Content Display ------------ -->
        <v-tabs v-model="tab">
          <v-tab>Details</v-tab>
          <v-tab>Options</v-tab>
          <v-tab>Notes</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <!-- ------------ Details ------------ -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Establishment</div>
                <v-text-field
                  label="Name of Establishment"
                  v-model="model.name"
                ></v-text-field>
                <v-select label="Type" :items="activityTypes" v-model="model.type"></v-select>
                <v-textarea
                  label="Description"
                  v-model="model.description"
                  rows="4">
                </v-textarea>
                <div class="overline mt-4">Currency</div>
                <v-autocomplete
                  :items="currencies"
                  label="Currency"
                  v-model="model.currency"
                  item-text="code"
                  return-object
                  @input="updateExchangeRate()"
                >
                  <template v-slot:item="{ item }">
                    {{ item.code }} - {{ item.name }}
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-if="model.currency && model.currency.code"
                  label="Exchange Rate"
                  v-model="model.currency.exchange"
                  :hint="
                    model.currency.symbol +
                    '1 ' +
                    model.currency.name +
                    ' = C$' +
                    model.currency.exchange +
                    ' Canadian Dollars'
                  "
                ></v-text-field>
                <div class="overline mt-4">Photo</div>
                <v-text-field label="Photo" v-model="model.photo"></v-text-field>
                <v-img
                  v-if="model.photo"
                  contain
                  :src="model.photo"
                  class="text-right"
                  height="150"
                  :style="{ background: model.photo ? 'white' : '#efefef' }"
                ></v-img>
              </div>
              <v-divider vertical></v-divider>
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Location</div>
                <v-text-field label="Address" v-model="model.location.address"></v-text-field>
                <div class="d-flex flex-row">
                  <v-text-field label="City" v-model="model.location.city"></v-text-field>
                  <v-text-field label="Province" v-model="model.location.province"></v-text-field>
                </div>
                <div class="d-flex flex-row">
                  <v-select
                    label="Country"
                    v-if="model.location.continent"
                    v-model="model.location.country"
                    :items="filtered_countries"
                    item-text="country"
                  ></v-select>
                  <v-select
                    label="Continent"
                    v-model="model.location.continent"
                    :items="continents"
                    item-text="name"
                  ></v-select>
                </div>
                <div class="overline mt-4">Contact</div>
                <v-text-field label="Name" v-model="model.contact.name"></v-text-field>
                <v-text-field label="Phone" v-model="model.contact.phone"></v-text-field>
                <v-text-field label="Mobile" v-model="model.contact.mobile"></v-text-field>
                <v-text-field label="Email" v-model="model.contact.email"></v-text-field>
                <v-text-field label="Website" v-model="model.contact.website"></v-text-field>
              </div>
            </div>
          </v-tab-item>
          <!-- ------------ Options ------------ -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="flex-1 mx-3">
                
              </div>
              <v-divider vertical></v-divider>
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Option</div>
                <v-alert v-if="model.options.length == 0" type="info" class="mb-0">
                  There are no options configured.
                </v-alert>
                <v-expansion-panels v-model="optionIndex">
                  <v-expansion-panel
                    v-for="(option, index) in model.options"
                    :key="'option' + index"
                  >
                  <v-expansion-panel-header>
                    <div class="d-flex flex-row align-center mr-3">
                      <div class="flex-1">{{ option.name }}</div>
                      <div class="text-caption" v-if="option.price && model.currency.code">
                        {{ model.currency.symbol }}
                        {{ option.price }}
                        {{ model.currency.code }}
                      </div>
                      <div class="text-caption" v-else-if="option.price && !model.currency.code">
                        ${{ option.price }} CAD
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-sheet class="pa-3" color="grey lighten-4" rounded>
                      <v-text-field
                        label="Name"
                        v-model="option.name"
                      ></v-text-field>
                      <v-text-field
                        label="Duration"
                        v-model="option.duration"
                      ></v-text-field>
                      <v-text-field
                        v-if="model.currency.code"
                        label="Price"
                        v-model="option.price"
                        :prefix="model.currency.symbol" 
                        :suffix="model.currency.code"
                        :hint="
                          option.price ? 
                            '$' + (option.price * model.currency.exchange).toFixed(2) + ' CAD' 
                          : 
                            model.currency.symbol +
                            '1 ' +
                            model.currency.name +
                            ' = C$' +
                            model.currency.exchange +
                            ' Canadian Dollars'
                        "
                      ></v-text-field>
                      <v-text-field
                        v-else
                        label="Price"
                        v-model="option.price"
                        prefix="$"
                        suffix="CAD"
                        hint="Local currency not selected"
                      ></v-text-field>
                      <v-text-field
                        label="Webpage Link"
                        v-model="option.url"
                      ></v-text-field>
                      <div class="d-flex flex-row mt-3">
                        <v-btn
                          small
                          @click="removeOption(index)"
                          text
                          color="red"
                          :disabled="model.options.length == 0"
                          >Remove Option</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn small text @click="optionIndex = null">Okay</v-btn>
                      </div>
                    </v-sheet>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-btn block text class="mt-3" @click="addOption()"
                ><v-icon left>mdi-plus</v-icon>Add Option</v-btn
              >
              </div>
            </div>
          </v-tab-item>
          <!-- ------------ Notes ------------ -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Special Notes</div>
                <v-textarea v-model="model.notes" outlined></v-textarea>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="model.id" small text color="red" @click="deleteDialog = true" :disabled="processing"><v-icon left>mdi-delete</v-icon>Delete Activity</v-btn>
        <v-btn v-else small text color="red" @click="go('AdminVendorsActivities')" :disabled="processing"><v-icon left>mdi-cancel</v-icon>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="model.id" color="primary" @click="update()" :loading="processing">
          <v-icon left>mdi-content-save-outline</v-icon>Save Activity
        </v-btn>
        <v-btn v-else color="primary" @click="create()" :loading="processing">
          <v-icon left>mdi-content-save-outline</v-icon>Add Activity
        </v-btn>
      </v-card-actions>
      <!-- ------------ Delete Activity Dialog ------------ -->
      <v-dialog v-model="deleteDialog" width="400">
        <v-card>
          <v-card-title class="headline">Delete Activity</v-card-title>
          <v-card-text>
            <v-alert type="error">
              This action is permanent and cannot be undone. 
            </v-alert>
            <v-checkbox v-model="deletionConfirmed" color="red" label="I understand the consequences"></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="deleteDialog=false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text :disabled="!deletionConfirmed" @click="permanentlyDelete()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-alert v-else-if="error" type="error">
      {{ error }}
    </v-alert>
  </div>
</template>
<script>
import { getFirestore, doc, addDoc, setDoc, onSnapshot, collection, deleteDoc } from 'firebase/firestore'
import axios from "axios";
export default {
  name: 'AdminVendorsActivity',
  props: ["user"],
  data: () => ({
    // Common data
    tab: 0,
    currencies: [],
    continents: [
      "Africa",
      "Antarctica",
      "Asia",
      "Oceania",
      "Europe",
      "North America",
      "South America",
    ],
    countries: [],
    activityTypes: [
      "On Tour: Included",
      "On Tour: Optional",
      "Pre-Tour: Optional",
    ],
    loading: false,
    processing: false,
    success: "",
    error: "",
    deleteDialog: false,
    deletionConfirmed: false,
    optionIndex: null,
    // Unique data
    defaultModel: {
      id: null,
      name: "", // name of establishment
      type: "",
      currency: {
        local: "CAD",
        conversion: 1,
        primary: "CAD",
      },
      location: {
        address: "",
        city: "",
        postalCode: "",
        country: "",
        continent: "",
      },
      contact: {
        name: "",
        phone: "",
        mobile: "",
        email: "",
        website: "",
      },
      options: [],
      notes: "",
      order: 0,
    },
    model: null,
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      // Load constant data
      this.countries = require("@/assets/continents.json");
      this.currencies = require("@/assets/currencies.json");
      // Load the id if it exists
      let id = this.$route.params.id;
      if (id == 'new') {
        this.model = JSON.parse(JSON.stringify(this.defaultModel));
      } else {
        this.loading = true;
        const db = getFirestore();
        const collectionRef = collection(db, 'activities');
        const docRef = doc(collectionRef, id);
        onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            this.model = doc.data();
            this.model.id = doc.id;
            this.loading = false;
          } else {
            this.error = "Activity not found.";
            this.model = null;
            this.loading = false;
          }
        });
      }
    },
    updateExchangeRate() {
      if (this.model.currency != undefined && this.model.currency.code) {
        let endpoint =
          "https://v6.exchangerate-api.com/v6/04107731562b261fdf9a93c8/pair/" +
          this.model.currency.code +
          "/CAD";
        axios.get(endpoint).then((response) => {
          if (response.status == 200 && response.data.result == "success") {
            this.model.currency.exchange = response.data.conversion_rate;
            this.$forceUpdate();
          } else {
            this.model.currency = null;
          }
        });
      }
    },
    addOption() {
      // Add a new option to all rooms
      this.model.options.push({
        name: "Untitled",
        duration: "",
        price: 0,
        url: "",
      });
      // Open the last index
      this.optionIndex = this.model.options.length - 1;
    },
    removeOption(index) {
      this.model.options.splice(index, 1);
    },
    create() {
      this.success = false;
      this.error = false;
      this.processing = true;
      const db = getFirestore();
      const collectionRef = collection(db, 'activities');
      addDoc(collectionRef, this.model).then((docRef) => {
        this.success = "Activity saved successfully.";
        this.model.id = docRef.id;
        this.processing = false;
      }).catch((error) => {
        this.error = "Error adding restaurant. Please try again.";
        this.processing = false;
      });
    },
    update() {
      this.loading = true;
      const db = getFirestore();
      const collectionRef = collection(db, 'activities');
      const docRef = doc(collectionRef, this.model.id);
      setDoc(docRef, this.model).then((docRef) => {
        this.success = "Activity updated successfully.";
        this.loading = false;
      }).catch((error) => {
        this.error = "Error updating activity. Please try again.";
        this.loading = false;
      });
    },
    permanentlyDelete() {
      this.loading = true;
      const db = getFirestore();
      const collectionRef = collection(db, 'activities');
      const docRef = doc(collectionRef, this.model.id);
      deleteDoc(docRef).then((docRef) => {
        // this.success = "Restaurant deleted successfully.";
        this.go('AdminVendorsActivities')
        this.loading = false;
      }).catch((error) => {
        this.error = "Error deleting hotel. Please try again.";
        this.loading = false;
      });
    },
  },
  watch: {
    user() {
      if (this.user) this.init();
    },
  },
  computed: {
    filtered_countries() {
      return this.countries.filter(
        (country) => country.continent == this.model.location.continent
      );
    },
  },
  filters: {
    formatCurrency(value) {
      return value.toFixed(2);
    },
  },
}
</script>