import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

var firebaseConfig = {}
let environment = 'development'

if (window.location.hostname == process.env.VUE_APP_PRODUCTION_HOSTNAME) {
  firebaseConfig = {
    apiKey: "AIzaSyClWYaVdm3YZ_Y4xkAifI1WBFGxNurPtV8",
    authDomain: "renedian-production.firebaseapp.com",
    projectId: "renedian-production",
    storageBucket: "renedian-production.appspot.com",
    messagingSenderId: "663653678477",
    appId: "1:663653678477:web:d980c2a8658767b57091c4",
    measurementId: "G-CTTFWEYMP5"
  };
  console.log('Production config loaded');
  environment = 'production';
} else {
  firebaseConfig = {
    apiKey: "AIzaSyBEcl4jhg1EH8acMc-fIls7ijI5BiWz7nQ",
    authDomain: "renedian-development.firebaseapp.com",
    projectId: "renedian-development",
    storageBucket: "renedian-development.appspot.com",
    messagingSenderId: "1075767191539",
    appId: "1:1075767191539:web:88eda762ac762f02809486",
    measurementId: "G-355CD89C92"
  };
  console.log('Development config loaded');
}

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

export { app, functions, analytics, environment };