<template>
  <div class="d-flex flex-1 flex-column">
    <v-main>
      <Header style="position: absolute; width: 100%" :user="user" :menu="menu" @logout="$emit('logout')"></Header>
      <router-view :user="user" @save="$emit('save')"/>
    </v-main>
    <Footer :user="user"></Footer>
  </div>
</template>
<script>
import Header from '/src/components/Header.vue'
import Footer from '/src/components/Footer.vue'
export default {
  name: 'StandardTemplate',
  props: ['user'],
  components: {
    Header, Footer
  },
  data: () => ({
    menu: [
      { id: 1, title: 'Tours', type: 'main', route: 'Tours' },
      { id: 2, title: 'Choosing Renedian', type: 'main',  route: 'Choose' },
      { id: 3, title: 'Rider Tales', type: 'main', route: 'Tales' },
      { id: 4, title: 'Moto Rentals', type: 'sub', route: 'Rentals' },
      { id: 5, title: 'Gear', type: 'sub', route: 'Gear' },
      { id: 6, title: 'Rider Portal', type: 'action', route: 'Portal' },
      { id: 7, title: 'Book Now', type: 'action', route: 'Booking' },
    ]
  }),
  created() {
    
  },
  methods: {
    
  }
}
</script>
<style>

</style>