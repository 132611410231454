<template>
  <div>
    <div class="d-flex flex-row pa-4 align-center">
      <div class="d-flex flex-1 align-center">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-3 d-flex d-md-none" color="#44413c"></v-app-bar-nav-icon>
        <!-- <img class="logo" src="~@/assets/images/renedian-logo.svg" @click="go('Landing')"> -->
        <img class="logo" src="~@/assets/images/renedian-logo.svg"><!-- Tempoarary removed link -->
      </div>
      <!-- ------------ Header Menu ------------ -->
      <div class="flex-column d-none d-md-flex">
        <div class="d-flex flex-row">
          <div v-for="(item, index) in menu" :key="'menu-'+index">
            <!-- Menu Item with Sub Menu -->
            <v-menu v-if="item?.children" bottom :offset-y="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small text v-bind="attrs" v-on="on">{{ item.title }} <v-icon right>mdi-chevron-down</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(subitem, subindex) in item.children" :key="'submenu-'+index+'-'+subindex" link @click="redirect(subitem.link)">
                  <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- Basic Menu Item -->
            <v-btn v-else small text @click="redirect(item.link)">{{ item.title }}</v-btn>
          </div>
          <!-- <v-btn v-for="item in filteredMainMenu" :key="item.id" small text @click="go(item.route)">
            {{ item.title }}
          </v-btn> -->
        </div>
        <div class="d-flex justify-end">
          <!-- <v-btn v-for="item in filteredSubMenu" :key="item.id" x-small text @click="go(item.route)">
            {{ item.title }}
          </v-btn> -->
        </div>
      </div>
      <div class="d-flex flex-row align-center">
        <v-btn small class="ml-2 d-none d-sm-flex" color="orange darken-3" @click="go('Booking')" depressed tile dark>Book Now</v-btn>
        <v-btn small class="ml-2" @click="go('PortalLogin')" depressed tile>Rider Portal</v-btn>
        <v-btn v-if="user" icon @click="$emit('logout')"><v-icon>mdi-logout</v-icon></v-btn>
      </div>
    </div>
    <!-- ---------------------- Navigation Drawer ---------------------- -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <!-- ------------ Avatar ------------ -->
      <v-list-item v-if="user">
        <v-list-item-avatar>
          <v-img :src="user.profile.avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user.profile.name }}</v-list-item-title>
        </v-list-item-content>
        <v-btn icon @click="$emit('logout')"><v-icon>mdi-logout</v-icon></v-btn>
      </v-list-item>
      <!-- ------------ Banner ------------ -->
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title class="text-h6 d-flex align-center">
            <img class="logo" src="~@/assets/images/renedian-logo.svg">
          </v-list-item-title>
          <!-- <v-list-item-subtitle>
            Renedian Adventures
          </v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
      <!-- ------------ Menu ------------ -->
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="menuGroup" active-class="orange--text text--accent-4"> 
          <v-list-item v-for="(item, index) in filteredMainMenu" :key="'drawer-menu-' + index" @click="go(item.route)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(item, index) in filteredSubMenu" :key="'drawer-submenu-' + index" @click="go(item.route)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- ------------ Actions (unauthenticated) ------------ -->
      <div class="px-2">
        <v-btn block class="my-2" color="orange" @click="go('Booking')" depressed tile>Book Now</v-btn>
        <v-btn block class="mb-2" @click="go('Portal')" depressed tile>Rider Portal</v-btn>
      </div>
      <!-- ------------ Actions (authenticated) ------------ -->
      <!-- <v-list-item link v-if="user" @click="$emit('logout')">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: ['user','menu'],
  data: () => ({
    drawer: false,
    menuGroup: null,
    menuIndex: 0,
    appName: 'Renedian',
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      this.menuIndex = this.menu.findIndex(item => item.route == this.$route.name)
    },
    redirect(url) {
      // window.open(url, '_blank')
      // redirect to url in same tab
      window.location.href = url
    },
  },
  watch: {
    '$route.name': function() {
      this.init()
    }
  },
  computed: {
    filteredMainMenu() {
      return this.menu.filter(item => item.type === 'main')
    },
    filteredSubMenu() {
      return this.menu.filter(item => item.type === 'sub')
    }
  },
}
</script>
<style scoped>
  .logo {
    height: 56px;
  }
</style>