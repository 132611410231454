<template>
  <v-card>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-book-edit-outline</v-icon>
      Bookings
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text v-if="!loading">
      Loaded {{ bookings.length }} bookings
    </v-card-text>
  </v-card>
</template>
<script>
import { getFirestore, onSnapshot, collection } from 'firebase/firestore'
export default {
  name: 'AdminBookings',
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'Code',
        align: 'start',
        sortable: false,
        value: 'code'
      },
      {
        text: 'Tour',
        sortable: true,
        value: 'summary'
      },
      {
        text: 'Customer Name',
        sortable: true,
        value: 'customer_name'
      },
      {
        text: 'Email',
        sortable: true,
        value: 'customer_email'
      },
      {
        text: 'Status',
        sortable: true,
        value: 'status_name'
      },
    ],
    // Data
    bookings: [],
  }),
  created() {
    this.loading = true
    this.initialize()
  },
  methods: {
    initialize() {
      const db = getFirestore()
      const collectionRef = collection(db, 'bookings')
      onSnapshot(collectionRef, (snapshot) => {
        this.loading = true
        let items = []
        snapshot.forEach((doc) => {
          let item = doc.data()
          item.id = doc.id
          items.push(item)
        })
        this.bookings = items
        this.loading = false
      })
    }
  },
}
</script>