<template>
  <v-card>
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-bed-outline</v-icon>
      {{ model }}s
      <v-spacer></v-spacer>
      <v-btn @click="go('AdminVendorsHotelAdd')" dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Hotel</v-btn>
    </v-card-title>
    <v-card-text v-if="!loading" class="mb-4">
      <div v-if="hotels.length">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search Hotels"
          single-line
          hide-details
          filled
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="filteredHotels"
          :items-per-page="20"
          class="elevation-1"
          @click:row="rowClicked"
        >
          <!-- Override -->
          <template v-slot:item.basis="{ item }">
            {{ computeBasis(item) }}
          </template>
        </v-data-table>
      </div>
      <div v-else class="mb-4">
        <v-alert type="info" outlined>There are no hotels configured</v-alert>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { getFirestore, doc, addDoc, setDoc, query, orderBy, onSnapshot, collection, deleteDoc } from 'firebase/firestore'
export default {
  name: 'AdminVendorsHotels',
  data: () => ({
    // Common data
    loading: false,
    saving: false,
    editing: false,
    deletionConfirmed: false,
    success: '',
    error: '',
    globalModel: null,
    // Data Table
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'type' },
      { text: 'Basis', value: 'basis' },
      { text: 'Country', value: 'location.country' },
      { text: 'Continent', value: 'location.continent' },
    ],
    // Dialogs
    modifyDialog: false,
    deleteDialog: false,
    // Unique data
    model: 'Hotel',
    collection: 'hotels',
    hotels: [],
    defaultModel: {
      id: null,
      name: '', // name of establishment
      currency: {
        local: 'CAD',
        conversion: 1,
        primary: 'CAD',
      },
      location: {
        address: '',
        city: '',
        postalCode: '',
        country: '',
      },
      contact: {
        name: '',
        phone: '',
        email: '',
        website: '',
      },
      order: 0
    },
    // Options
    establishmentTypes: [ 
      'hotel', 
      'lodge', 
      'guest house' 
    ],
    meals: {
      breakfast: {
        included: false,
        cost: {
          amount: 0,
          currency: 'CAD',
        }
      },
      lunch: {
        included: false,
        cost: {
          amount: 0,
          currency: 'CAD',
        }
      },
      dinner: {
        included: false,
        cost: {
          amount: 0,
          currency: 'CAD',
        },
        guide: {

        }
      },
    },
    rates: {

    }
  }),
  created() {
    const db = getFirestore()
    const collectionRef = collection(db, this.collection)
    onSnapshot(collectionRef, (snapshot) => {
      this.loading = true
      let items = []
      snapshot.forEach((doc) => {
        let item = doc.data()
        item.id = doc.id
        items.push(item)
      })
      this.hotels = items
      this.loading = false
    })
  },
  methods: {
    create() {
      this.globalModel = JSON.parse(JSON.stringify(this.defaultModel))
      this.editing = false
      this.success = false
      this.error = false
      this.modifyDialog = true
    },
    edit(object) {
      this.editing = true
      this.success = false
      this.error = false
      this.globalModel = object
      this.modifyDialog = true
    },
    deleteRecord(object) {
      this.success = false
      this.error = false
      this.globalModel = object
      this.deletionConfirmed = false
      this.deleteDialog = true
    },
    computeBasis(hotel) {
      // // BB = Bed and Breakfast
      // // DBB = Dinner, Bed and Breakfast
      // // R Only = Room Only
      // DBBL = Double Room, meaning two people in one bed
      // TWIN  = Two beds in one room, normally for two guys sharing a room
      // SNGL = Either only one bed in the room, or only one person staying in a regular room (fee applied)
      if (!hotel.meals.breakfast.included && !hotel.meals.lunch.included && !hotel.meals.dinner.included) return 'Room Only'
      if (hotel.meals.breakfast.included && hotel.meals.lunch.included && hotel.meals.dinner.included) return 'LDBB'
      if (hotel.meals.breakfast.included && hotel.meals.dinner.included) return 'DBB'
      if (hotel.meals.breakfast.included) return 'BB'
    },
    save() {
      // this.saving = true
      // const db = getFirestore()
      // const collectionRef = collection(db, this.collection)
      // this.globalModel.order = this.vehicles.length + 1
      // addDoc(collectionRef, this.globalModel).then((docRef) => {
      //   this.modifyDialog = false
      //   this.success = this.model + " added successfully."
      //   this.saving = false
      // }).catch((error) => {
      //   this.error = "Error adding " + this.model + ". Please try again."
      // })
    },
    update() {
      // const db = getFirestore()
      // const collectionRef = collection(db, this.collection)
      // const docRef = doc(collectionRef, this.globalModel.id)
      // setDoc(docRef, this.globalModel).then((docRef) => {
      //   this.modifyDialog = false
      //   this.saving = false
      //   this.success = this.model + " updated successfully."
      // }).catch((error) => {
      //   this.error = "Error updating " + this.model + ". Please try again."
      // })
    },
    permanentlyDelete() {
      const db = getFirestore()
      const collectionRef = collection(db, this.collection)
      const docRef = doc(collectionRef, this.globalModel.id)
      deleteDoc(docRef).then(() => {
        this.deleteDialog = false
        this.success = this.model + " deleted successfully."
      }).catch((error) => {
        this.error = "Error deleting " + this.model + ". Please try again."
      })
    },
    rowClicked(item) {
      this.go('AdminVendorsHotelEdit', {id: item.id})
    },
  },
  computed: {
    filteredHotels() {
      if (!this.search) {
        return this.hotels
      }
      const searchLower = this.search.toLowerCase()
      return this.hotels.filter(hotel => {
        const name = hotel.name.toLowerCase()
        const type = hotel.type.toLowerCase()
        const basis = this.computeBasis(hotel).toLowerCase()
        const country = hotel.location.country.toLowerCase()
        const continent = hotel.location.continent.toLowerCase()
        
        return (
          name.includes(searchLower) ||
          type.includes(searchLower) ||
          basis.includes(searchLower) ||
          country.includes(searchLower) ||
          continent.includes(searchLower)
        )
      })
    }
  },
  filters: {
    formatCurrency(value) {
      if (!value) return '$0'
      if (value < 1000) return '$' + value
      value = value.toString().replace(/^0+/, '') // remove any leading zeros
      return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
</script>