<template>
  <v-card>
    <v-progress-linear v-if="loading" color="orange" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-map-outline</v-icon>
      Current Tour
      <v-spacer></v-spacer>
      <!-- <v-btn dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Hotel</v-btn> -->
    </v-card-title>
    <v-card-text>
      <!-- <v-tabs v-model="tab" fixed-tabs dark background-color="orange darken-3"> -->
      <v-tabs v-model="tab" fixed-tabs show-arrows>
        <v-tab>Pre-Tour Checklist</v-tab>
        <v-tab>Itinerary</v-tab>
        <v-tab>Daily Reports</v-tab>
        <v-tab>Rider Manifest</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-alert type="error">
            <strong>Required</strong><br>
            Guide pre-tour checklist
          </v-alert>
        </v-tab-item>
        <v-tab-item>
          <v-alert type="error">
            <strong>Required</strong><br>
            <ul>
              <li>Examples of the information</li>
              <li>How should this be presented?</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>Itinerary</strong><br>
            <ul>
              <li>Hotel confirmation details + contact details</li>
              <li>Restaurant confirmation details + contact details</li>
              <li>activity confirmation details + contact details</li>
              <li>Talking points during evening briefing (for following day)</li>
            </ul>
          </v-alert>
        </v-tab-item>
        <v-tab-item>
          <v-alert type="error">
            <strong>Required</strong><br>
            <ul>
              <li>Example feedback</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>Daily Data</strong><br>
            <ul>
              <li>Upload feedback</li>
              <li>Upload receipts: Decision was made to attempt to have these uploaded directly on Springbok</li>
            </ul>
          </v-alert>
        </v-tab-item>
        <v-tab-item>
          <v-alert type="error">
            <strong>Required</strong><br>
            <ul>
              <li>Example rider manifest</li>
            </ul>
          </v-alert>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdminGuideCurrentTour',
  data: () => ({
    loading: false,
    tab: null,
  }),
  created() {
    
  },
  methods: {
    
  },
}
</script>
