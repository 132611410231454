<template>
  <v-sheet>
    Reviews
  </v-sheet>
</template>
<script>
export default {
  name: 'Reviews',
  data: () => ({
    //
  }),
  created() {
    
  }
}
</script>