<template>
  <div class="d-flex flex-1 flex-column">
    <Header :user="user" :menu="menu" @logout="$emit('logout')"></Header>
    <v-main>
      <router-view :user="user" @save="$emit('save')"/>
    </v-main>
    <Footer :user="user"></Footer>
  </div>
</template>
<script>
import Header from '/src/components/Header.vue'
import Footer from '/src/components/Footer.vue'
export default {
  name: 'StandardTemplate',
  props: ['user'],
  components: {
    Header, Footer
  },
  data: () => ({
    menu: [
      { title: 'Home', route: '', link: 'https://renedian.com/' },
      { title: 'About', children: [
        { title: 'About Renedian Motorcycle Tours', route: '', link: 'https://renedian.com/about/' },
        { title: 'About Rene Cormier', route: '', link: 'https://renedian.com/about/about-rene-cormier/' },
        { title: 'Our Worldwide Crew', route: '', link: 'https://renedian.com/about/our-worldwide-crew-tour-guides/' },
        { title: 'The University of Gravel Roads', route: '', link: 'https://renedian.com/about/the-university-of-gravel-roads/' },
      ] },
      { title: 'Tours', children: [
        { title: 'Africa Motorcycle Tours', route: '', link: 'https://renedian.com/adventure-motorcycle-tours/africa/' },
        { title: 'Mongolia Motorcycle Tours', route: '', link: 'https://renedian.com/adventure-motorcycle-tours/mongolia/' },
        { title: 'Canada Motorcycle Tours', route: '', link: 'https://renedian.com/adventure-motorcycle-tours/canada/' },
        { title: 'South America Motorcycle Tours', route: '', link: 'https://renedian.com/adventure-motorcycle-tours/south-america/' },
        { title: 'Scotland Motorcycle Tours', route: '', link: 'https://renedian.com/adventure-motorcycle-tours/scotland/' },
        { title: 'New Zealand Motorcycle Tours', route: '', link: 'https://renedian.com/adventure-motorcycle-tours/new-zealand/' },
      ] },
      { title: 'Gear', route: '', link: 'https://renedian.com/gear-and-motorcycles/' },
      { title: 'Writings & Reviews', children: [
        { title: 'Local Lore', route: '', link: 'https://renedian.com/local-lore/' },
        { title: 'Rider Stories', route: '', link: 'https://renedian.com/rider-stories/' },
      ] },
    ]
  }),
  created() {
    
  },
  methods: {
    
  }
}
</script>
<style>

</style>