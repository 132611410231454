<template>
  <v-container>
    <div class="r-title d-flex justify-center mt-6 mb-12">
      Waterfalls & Wildlife
    </div>
    Tour details here.
    <v-btn @click="go('BookingWithId', { id: 'A2XHZyDXJEtkHdoBhmCu' })" color="primary" class="white--text">
      Book This Tour
    </v-btn>
  </v-container>
</template>
<script>
export default {
  name: 'WaterfallsAndWildlife',
}
</script>