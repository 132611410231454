<template>
  <v-card>
    <v-progress-linear v-if="loading" color="orange" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-account-box</v-icon>
      Guide Profile
      <v-spacer></v-spacer>
      <!-- <v-btn dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add Hotel</v-btn> -->
    </v-card-title>
    <v-card-text>
      <!-- <v-alert type="error">
      </v-alert> -->
      <!-- <v-alert type="warning">
      </v-alert> -->
      <v-alert type="info">
        <strong>Personal Documentation Area</strong>
        <ul>
          <li>
            Ability to upload personal documentation such as:
            <ul>
              <li>Driver's License</li>
              <li>Passport</li>
              <li>Visa details</li>
              <li>Travel insurance details</li>
              <li>Certifications (First aid, Guide etc.)</li>
              <li>Flight details (for specific tour)</li>
            </ul>
          </li>
        </ul>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdminGuideProfile',
  data: () => ({
    loading: false
  }),
  created() {
    
  },
  methods: {
    
  },
}
</script>
