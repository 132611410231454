<template>
  <v-container>
    <v-card>
      <v-card-title>
        Pending Approval
      </v-card-title>
      <v-card-text>
        Your account is currently pending approval. Please contact your administrator if you have any questions.
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  export default {
    name: 'AdminPending',
  }
</script>