<template>
  <v-card>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-robot-outline</v-icon>
      Artificial Intelligence
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-alert type="info">
        <strong>Email Composition</strong>
        <ul>
          <li>Draft and send email</li>
        </ul>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'AdminAutomationAI',
    data: () => ({
      loading: false
    }),
  }
</script>