<template>
  <v-card>
    <v-progress-linear v-if="loading.tours" color="primary" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-map-outline</v-icon>
      Tours
      <v-spacer></v-spacer>
      <v-btn @click="createTour()" dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>New Tour</v-btn>
    </v-card-title>
    <v-card-text v-if="!loading.tours" class="mb-4">
      <div v-if="tours.length">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search Tours"
          single-line
          hide-details
          filled
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="filteredTours"
          :items-per-page="20"
          class="elevation-1"
          @click:row="editTour"
        >
          <template v-slot:item.template.location.countries="{ item }">
            {{ formatCountries(item.template.location.countries) }}
          </template>
          <template v-slot:item.schedule.primary.start="{ item }">
            {{ formatDate(item.schedule.primary.start) }}
          </template>
          <template v-slot:item.schedule.primary.finish="{ item }">
            {{ formatDate(item.schedule.primary.finish) }}
          </template>
        </v-data-table>
      </div>
      <div v-else class="mb-4">
        <v-alert type="info" outlined>There are no upcoming tours</v-alert>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { getFirestore, collection, onSnapshot } from 'firebase/firestore'
import { DateTime } from "luxon"

export default {
  name: 'AdminTours',
  data: () => ({
    // ------------ Global ------------
    calendarDate: '',
    loading: {
      tours: true,
    },
    search: '',
    headers: [
      { text: 'Continent', value: 'template.location.continent' },
      { text: 'Country', value: 'template.location.countries' },
      { text: 'Tour Name', align: 'start', value: 'details.name' },
      { text: 'Start', value: 'schedule.primary.start'},
      { text: 'Finish', value: 'schedule.primary.finish' },
    ],
    events: [],
    // ------------ Tour ------------
    tours: [],
    // ------------ Data ------------
  }),
  created() {
    const db = getFirestore()
    const collectionRef = collection(db, 'tours')
    onSnapshot(collectionRef, (snapshot) => {
      this.loading.tours = true
      let tours = []
      snapshot.forEach((doc) => {
        let tour = doc.data()
        tour.id = doc.id
        tours.push(tour)
      })
      this.tours = tours
      this.loading.tours = false
    })
  },
  methods: {
    createTour() {
      this.$router.push({ name: 'AdminToursManage', params: { id: 'new' } })
    },
    editTour(item) {
      this.$router.push({ name: 'AdminToursManage', params: { id: item.id } })
    },
    formatCountries(countries) {
      return countries.join(', ')
    },
    formatDate(value) {
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy')
    }
  },
  computed: {
    filteredTours() {
      if (!this.search) {
        return this.tours
      }
      const searchLower = this.search.toLowerCase()
      return this.tours.filter(tour => {
        const continent = tour.template.location.continent.toLowerCase()
        const countries = tour.template.location.countries.join(', ').toLowerCase()
        const name = tour.details.name.toLowerCase()
        const start = this.formatDate(tour.schedule.primary.start).toLowerCase()
        const finish = this.formatDate(tour.schedule.primary.finish).toLowerCase()

        return (
          continent.includes(searchLower) ||
          countries.includes(searchLower) ||
          name.includes(searchLower) ||
          start.includes(searchLower) ||
          finish.includes(searchLower)
        )
      })
    }
  },
  filters: {
    formatDate(value) {
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy')
    }
  }
}
</script>

<style>
.v-text-field--filled.v-text-field--single-line input {
  margin-top: 0 !important;
}
</style>
