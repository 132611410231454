<template>
  <div>
    <img style="width: 100%" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Flanding-mockup.jpg?alt=media&token=e6a1ab84-9f9a-499d-9dfb-bc576808afa2">
  </div>
  <!-- <div class="home"> -->
    <!-- Hero + Map -->
    <!-- <HeroicMap />     -->
    <!-- Top Tours -->
    <!-- <TopTours /> -->
    <!-- Featured Tour -->
    <!-- <FeaturedTours /> -->
    <!-- About Renedian -->
    <!-- <AboutRenedian /> -->
    <!-- Choosing Renedian -->
    <!-- <ChoosingRenedian /> -->
    <!-- Reviews -->
    <!-- <Reviews /> -->
    <!-- Rider Tales -->
    <!-- <RiderTales /> -->
    <!-- Statistics -->
    <!-- <Statistics /> -->
  <!-- </div> -->
</template>

<script>
import HeroicMap from '@/blocks/HeroicMap.vue'
import TopTours from '@/blocks/TopTours.vue'
import FeaturedTours from '@/blocks/FeaturedTours.vue'
import AboutRenedian from '@/blocks/AboutRenedian.vue'
import ChoosingRenedian from '@/blocks/ChoosingRenedian.vue'
import Reviews from '@/blocks/Reviews.vue'
import RiderTales from '@/blocks/RiderTales.vue'
import Statistics from '@/blocks/Statistics.vue'
export default {
  name: 'Landing',
  components: {
    HeroicMap,
    TopTours,
    FeaturedTours,
    AboutRenedian,
    ChoosingRenedian,
    Reviews,
    RiderTales,
    Statistics
  },
}
</script>
