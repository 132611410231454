<template>
  <v-card>
    <v-progress-linear v-if="loading" color="orange" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-email-fast-outline</v-icon>
      Automated Email
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-alert type="warning">
        @Rene: To provide email template content for all messages
      </v-alert>
      <v-alert type="info">
        <strong>A Note from Rene</strong>
        <ul>
          <li>Timing:  Immediately upon booking and receipt of deposit</li>
          <li>Content details: Thank you note from Rene</li>
          <li>Email to come from: rene@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>Booking Confirmation</strong>
        <ul>
          <li>Timing:  10 minutes after booking</li>
          <li>
            Content details: invoice showing which trip (name and dates), 
            motorcycle selection, any motorcycle details (I.e. Low seat, top case, tank bag)
            rooming selection (solo or shared) reiterate cancellation policy and link to page with all details on cxl and terms and conditions.
            Invoice should also show deposit paid, the currency of transaction, when the balance is due (and what the amount currently is).
            There should also be mention of what to expect next in terms of our communication to them.
            This email also needs to include link to the rider portal (email address as user name and temp password).
          </li>
          <li>Email to come from: info@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>Monthly Check-in</strong>
        <ul>
          <li>Timing:  Every month from date of booking until 60 days before trip starts.</li>
          <li>
            Content details: 
            Fun, quick stories about where they are travelling to [topics to include animals, history, mountain passes, etc] 
            Reminder at the bottom of the email that provides link to rider Portal that they can use to upload insurance details, flights details, any pre or post trip needs, etc, that have not been completed yet (We recognize that this information will be available in the 2-12 months before the trip runs, so need to provide regular opportunities for riders to upload their details when convenient for them)
          </li>
          <li>Email to come from: info@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>75 Day reminder</strong>
        <ul>
          <li>Timing:  75 days before trip starts</li>
          <li>
            Content details: 
            A friendly reminder that the final invoice is due in 2 weeks. 
            Link to invoice, and review of payment options. 
          </li>
          <li>Email to come from: info@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>60 Day Final Invoice</strong>
        <ul>
          <li>Timing:  61 days before trip starts</li>
          <li>
            Content details: 
            A friendly reminder that the final invoice is due. 
            Link to invoice, and review of payment options.
            Review cancellation policy
            Review cancellation fees due
          </li>
          <li>Email to come from: info@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>Meet your Crew!</strong>
        <ul>
          <li>Timing: 45 days before tour starts</li>
          <li>
            Content details: 
            An electronic introduction from the guide and driver on that tour, as well as Danise in the office joint email from guide and driver
            encouraging pax to contact them directly if any questions arise. Or they are welcome to continue to contact the office. 
          </li>
          <li>Email to come from: info@renedian.com (cc guide/driver/Danise)</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>30 Day: Packing List</strong>
        <ul>
          <li>Timing:  30 days before trip starts</li>
          <li>
            Content details: 
            Reminder with a link to the packing list in Rider Portal
            and final items to be done (visit to travel doctor)
          </li>
          <li>Email to come from: info@renedian.com, cc in guide and driver.</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>14 Day: Arrival Details & Let’s check in with a phone call!</strong>
        <ul>
          <li>Timing:  14 days before tour</li>
          <li>
            Content details: 
            Confirming Flight details - Aaron - can this be extracted from the Rider Portal Database?
            Further, how can we automate details such as pre/post hotels, airport transfer instructions - would there be wisdom in creating a field for this in the rider portal? Danise can complete these details if we do the booking, otherwise they will have to do it themselves. 
            Detailed schedule for the first 48 hours of their trip.
            Offer riders the opportunity to get on the phone with either their guide, Danise, or someone from the Renedian office to go over any last minute details.  Let them know this will be the last scheduled communication before the trip starts, although they are welcome to contact us via phone, email, to the guide or driver or Danise at any time if they wish.
          </li>
          <li>Email to come from: Danise@renedian.com, cc in guide and driver.</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>Notice on Guide Tipping</strong>
        <ul>
          <li>Timing: Five days before the last tour date</li>
          <li>
            Content details: 
            Re-iteration of suggested tipping amounts for exceptional service
            Details on how to pay tip via credit card (link) 
            Upon payment of tip, automated email will be sent with confirmation tip amount
            Note to guide field for the rider to leave a comment that will go out in email. 
          </li>
          <li>Email to come from: danise@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>Departure Details</strong>
        <ul>
          <li>Timing: Two days before the end of the tour</li>
          <li>
            Content details: 
            Details confirming any extra end of tour hotels
            flight details, and airport transfer times and details. 
          </li>
          <li>Email to come from: danise@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>Wrap up</strong>
        <ul>
          <li>Timing: Last day of  tour (fly out day).</li>
          <li>
            Content details: 
            A thank you for participation/riding with us
            Reminder of the Alumni program (details) 
            Request for review on Google, Trip Advisor, etc. 
            A not that Liz will contact them to request a final survey 
          </li>
          <li>Email to come from: guide and driver @ renedian.com + cc: info@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>A final note from Rene</strong>
        <ul>
          <li>Timing: Tour end + 7 days</li>
          <li>
            Content details: 
            Mimic the pre-trip note in style and form, a word of thanks from me, and also a sentence saying that although you have likely shared feedback with Liz on her asked for survey, if there are any feedback from the trip that they would like to pass along to me in confidence, I would greatly appreciate them sending it directly to my email or phoning me on my direct line.
          </li>
          <li>Email to come from: rene@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>1 Year Anniversary of Tour start date</strong>
        <ul>
          <li>Timing: Tour start date + 1yr</li>
          <li>
            Nice note about starting a year ago (TBC)
            Reminder of Alumni Program
            Reminder to check out www.advmotorcycleparts.com
            Reminder to check out www.canadianmotorcyclerentals.com
            Reminder to check out Facebook Alumni Page
            Reminder that we do private tours
            Ask for referrals
          </li>
          <li>Email to come from: rene@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>Complimentary Jacket</strong>
        <ul>
          <li>Timing: After 3 tours</li>
          <li>
            Send an email to Rene? to arrange shipment of jacket
          </li>
          <li>Email to come from: rene@renedian.com</li>
        </ul>
      </v-alert>
      <v-alert type="info">
        <strong>Tour Emails (later phase)</strong>
        <ul>
          <li>Timing: Daily (on tour)</li>
          <li>Daily itinerary</li>
          <li>Weather</li>
          <li>Activities?</li>
          <li>Email to come from: guide?</li>
        </ul>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdminAutomationEmail',
  data: () => ({
    loading: false
  }),
  created() {
    
  },
  methods: {
    
  },
}
</script>
