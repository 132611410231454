<template>
  <v-card>
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
    ></v-progress-linear>
    <v-card-title>
      <v-btn @click="go('AdminVendorsHotels')" icon class="mr-3"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >
      <v-icon color="orange darken-3" left>mdi-bed-outline</v-icon>
      <span>{{ model.name ? model.name : "Add Hotel" }}</span>
    </v-card-title>
    <v-card-text>
      <v-alert type="success" v-if="success" dismissible>{{ success }}</v-alert>
      <v-alert type="error" v-if="error" dismissible>{{ error }}</v-alert>
      <!-- ------------ Primary Content Display ------------ -->
      <v-tabs v-model="tab">
        <v-tab>Details</v-tab>
        <v-tab>Rooms</v-tab>
        <v-tab>Meals</v-tab>
        <v-tab>Notes</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <!-- ------------ Details ------------ -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 mx-3">
              <div class="overline mt-4">Establishment</div>
              <v-text-field
                label="Name of Establishment"
                v-model="model.name"
              ></v-text-field>
              <v-select
                label="Type of Establishment"
                v-model="model.type"
                :items="establishmentTypes"
              ></v-select>
              <div class="overline mt-4">Currency</div>
              <v-autocomplete
                :items="currencies"
                label="Currency"
                v-model="model.currency"
                item-text="code"
                return-object
                @input="updateExchangeRate()"
              >
                <template v-slot:item="{ item }">
                  {{ item.code }} - {{ item.name }}
                </template>
              </v-autocomplete>
              <v-text-field
                v-if="model.currency && model.currency.code"
                label="Exchange Rate"
                v-model="model.currency.exchange"
                :hint="
                  model.currency.symbol +
                  '1 ' +
                  model.currency.name +
                  ' = C$' +
                  model.currency.exchange +
                  ' Canadian Dollars'
                "
              ></v-text-field>
              <div class="overline mt-4">Photo</div>
              <v-text-field label="Photo" v-model="model.photo"></v-text-field>
              <v-img
                contain
                :src="model.photo"
                class="text-right"
                height="150"
                :style="{ background: model.photo ? 'white' : '#efefef' }"
              ></v-img>
            </div>
            <v-divider vertical></v-divider>
            <div class="flex-1 mx-3">
              <div class="overline mt-4">Location</div>
              <div class="d-flex flex-row">
                <v-text-field label="Address" v-model="model.location.address"></v-text-field>
                <v-text-field label="City" v-model="model.location.city"></v-text-field>
              </div>
              <div class="d-flex flex-row">
                <v-text-field label="Postal Code" v-model="model.location.postal"></v-text-field>
                <v-text-field label="Province" v-model="model.location.province"></v-text-field>
              </div>
              <div class="d-flex flex-row">
                <v-select
                  label="Country"
                  v-if="model.location.continent"
                  v-model="model.location.country"
                  :items="filtered_countries"
                  item-text="country"
                ></v-select>
                <v-select
                  label="Continent"
                  v-model="model.location.continent"
                  :items="continents"
                  item-text="name"
                ></v-select>
              </div>
              <div class="overline mt-4">Contact</div>
              <v-text-field label="Name" v-model="model.contact.name"></v-text-field>
              <v-text-field label="Phone" v-model="model.contact.phone"></v-text-field>
              <v-text-field label="Email" v-model="model.contact.email"></v-text-field>
              <v-text-field label="Website" v-model="model.contact.website"></v-text-field>
            </div>
          </div>
        </v-tab-item>
        <!-- ------------ Rooms ------------ -->
        <v-tab-item>
          <div class="d-flex flex-column flex-md-row">
            <div class="flex-1 pa-1">
              <div class="overline mt-4">Room Options</div>
              <v-card v-if="model.rooms.length == 0" class="pa-3" elevation="3">
                No rooms added
              </v-card>
              <v-expansion-panels v-else v-model="roomIndex">
                <v-expansion-panel
                  v-for="(room, index) in model.rooms"
                  :key="room.index"
                >
                  <v-expansion-panel-header>
                    <div
                      v-if="room.description"
                      class="d-flex flex-row align-center"
                    >
                      <div
                        v-if="room.description"
                        class="font-weight-bold flex-1"
                      >
                        {{ room.description }} ({{ room.type }})
                      </div>
                      <!-- <span v-if="room.rates.sto" class="mr-3 d-none d-md-flex text-caption">{{ model.currency.symbol }}{{ room.rates.sto }}</span> -->
                      <!-- <span v-if="room.rates.sto && model.currency.exchange" class="mr-3 d-none d-md-flex text-caption">CAD${{ room.rates.sto * model.currency.exchange | formatCurrency }}</span> -->
                    </div>
                    <div v-else class="font-italic">Untitled Room Option</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-sheet class="pa-3" color="grey lighten-4" rounded>
                      <v-text-field
                        label="Description"
                        v-model="room.description"
                      ></v-text-field>
                      <v-select
                        label="Room Type"
                        v-model="room.type"
                        append-icon="mdi-chevron-down"
                        :items="roomTypes"
                      ></v-select>
                      <div
                        class="mt-3"
                        v-for="(season, index) in model.seasons"
                        :key="'seasonRateIndex' + index"
                      >
                        <div class="overline" v-if="model.seasons.length > 1">
                          {{ season.name }} Season
                        </div>
                        <div class="d-flex flex-row">
                          <v-text-field label="Rack Rate" v-model="room.rates[index].rack" :prefix="model.currency.symbol" :suffix="model.currency.code"></v-text-field>
                          <v-text-field label="STO Rate" v-model="room.rates[index].sto" class="mx-3" :prefix="model.currency.symbol" :suffix="model.currency.code"></v-text-field>
                          <v-text-field label="Guide Rate"  v-model="room.rates[index].guide" :prefix="model.currency.symbol" :suffix="model.currency.code"></v-text-field>
                        </div>
                      </div>
                      <div class="d-flex flex-row">
                        <v-btn
                          small
                          @click="removeRoomOption(index)"
                          text
                          color="red"
                          >Remove Option</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn small text @click="roomIndex = null">Okay</v-btn>
                      </div>
                    </v-sheet>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-btn block text class="mt-3" @click="addRoomOption()"
                ><v-icon left>mdi-plus</v-icon>Add Room Option</v-btn
              >
            </div>
            <v-divider class="d-flex d-sm-none"></v-divider>
            <v-divider vertical class="d-none d-sm-flex mx-3"></v-divider>
            <div class="flex-1 pa-1">
              <div class="overline mt-4">Seasonal Rates</div>
              <v-expansion-panels v-model="seasonIndex">
                <v-expansion-panel
                  v-for="(season, index) in model.seasons"
                  :key="'season' + index"
                >
                  <v-expansion-panel-header>
                    <div class="d-flex flex-row align-center mr-3">
                      <div class="flex-1">{{ season.name }}</div>
                      <div class="text-caption" v-if="season.range.length == 2">
                        {{ season.start }} - {{ season.finish }}
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-sheet class="pa-3" color="grey lighten-4" rounded>
                      <v-text-field
                        label="Name"
                        v-model="season.name"
                      ></v-text-field>
                      <div class="d-flex flex-row mt-3">
                        <v-text-field
                          label="Start Date"
                          v-model="season.start"
                          @input="dateInput(season)"
                          type="date"
                          class="mr-2"
                        ></v-text-field>
                        <v-text-field
                          label="Finish Date"
                          v-model="season.finish"
                          @input="dateInput(season)"
                          type="date"
                          class="ml-2"
                        ></v-text-field>
                      </div>
                      <v-date-picker
                        v-model="season.range"
                        range
                        @input="dateRangeInput(season)"
                      ></v-date-picker>
                      <div class="d-flex flex-row mt-3">
                        <v-btn
                          small
                          @click="removeSeason(index)"
                          text
                          color="red"
                          :disabled="model.seasons.length == 1"
                          >Remove Season</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn small text @click="seasonIndex = null"
                          >Okay</v-btn
                        >
                      </div>
                    </v-sheet>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-btn block text class="mt-3" @click="addSeason()"
                ><v-icon left>mdi-plus</v-icon>Add Season</v-btn
              >
            </div>
          </div>
        </v-tab-item>
        <!-- ------------ Meals ------------ -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 mx-3 mb-3">
              <div class="overline mt-4">Included Meals</div>
              <v-chip-group
                multiple
                v-model="model.meals.index"
                @input="filterChange()"
                @change="filterChange()"
              >
                <v-chip filter outlined>Breakfast</v-chip>
                <v-chip filter outlined>Lunch</v-chip>
                <v-chip filter outlined>Dinner</v-chip>
              </v-chip-group>
              <div class="overline mt-4">Meal Rates</div>
              <v-text-field
                v-if="model.meals.breakfast.included"
                label="Breakfast Rate"
                v-model="model.meals.breakfast.cost"
                class="mr-3"
                :prefix="model.currency.symbol"
                :suffix="model.currency.code"
                hide-details
              ></v-text-field>
              <div
                v-if="model.meals.breakfast.cost > 0 && model.currency.exchange"
                class="text-right mr-3"
              >
                CAD${{
                  (model.meals.breakfast.cost * model.currency.exchange)
                    | formatCurrency
                }}
              </div>
              <div v-else class="mt-6"></div>
              <v-text-field
                v-if="model.meals.lunch.included"
                label="Lunch Rate"
                v-model="model.meals.lunch.cost"
                class="mr-3"
                :prefix="model.currency.symbol"
                :suffix="model.currency.code"
                hide-details
              ></v-text-field>
              <div
                v-if="model.meals.lunch.cost > 0 && model.currency.exchange"
                class="text-right mr-3"
              >
                CAD${{
                  (model.meals.lunch.cost * model.currency.exchange)
                    | formatCurrency
                }}
              </div>
              <div v-else class="mt-6"></div>
              <v-text-field
                v-if="model.meals.dinner.included"
                label="Dinner Rate"
                v-model="model.meals.dinner.cost"
                class="mr-3"
                :prefix="model.currency.symbol"
                :suffix="model.currency.code"
                hide-details
              ></v-text-field>
              <div
                v-if="model.meals.dinner.cost > 0 && model.currency.exchange"
                class="text-right mr-3"
              >
                CAD${{
                  (model.meals.dinner.cost * model.currency.exchange)
                    | formatCurrency
                }}
              </div>
            </div>
            <!-- <v-divider vertical></v-divider> -->
            <div class="flex-1 mx-3"></div>
          </div>
        </v-tab-item>
        <!-- ------------ Notes ------------ -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 mx-3">
              <div class="overline mt-4">Special Notes</div>
              <v-textarea v-model="model.notes" outlined></v-textarea>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <v-btn small text color="red" @click="deleteDialog = true"><v-icon left>mdi-delete</v-icon>Delete Hotel</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="model.id" color="primary" @click="update()">
        <v-icon left>mdi-content-save-outline</v-icon>Save Hotel
      </v-btn>
      <v-btn v-else color="primary" @click="create()">
        <v-icon left>mdi-content-save-outline</v-icon>Add Hotel
      </v-btn>
    </v-card-actions>
    <!-- ------------ Delete Adventure Dialog ------------ -->
    <v-dialog v-model="deleteDialog" width="400">
      <v-card>
        <v-card-title class="headline">Delete Hotel</v-card-title>
        <v-card-text>
          <v-alert type="error">
            This action is permanent and cannot be undone. 
          </v-alert>
          <v-checkbox v-model="deletionConfirmed" color="red" label="I understand the consequences"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="deleteDialog=false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text :disabled="!deletionConfirmed" @click="permanentlyDeleteHotel()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import axios from "axios";
import {
  getFirestore,
  doc,
  addDoc,
  setDoc,
  query,
  orderBy,
  onSnapshot,
  collection,
  deleteDoc,
} from "firebase/firestore";
export default {
  name: "AdminVendorsHotels",
  props: ["user"],
  data: () => ({
    // Common data
    tab: 0,
    currencies: [],
    continents: [
      "Africa",
      "Antarctica",
      "Asia",
      "Oceania",
      "Europe",
      "North America",
      "South America",
    ],
    establishmentTypes: ["Hotel", "Lodge", "Guest House"],
    roomTypes: ["Single Room", "Double Room", "Twin Room"],
    countries: [],
    roomIndex: null,
    seasonIndex: null,
    // Unique data
    id: null,
    model: {
      id: "",
      name: "",
      type: "",
      basis: "",
      photo: "",
      currency: {
        local: "CAD",
        primary: "CAD",
        conversion: 1,
        data: null,
      },
      location: {
        address: "",
        city: "",
        province: "",
        postal: "",
        country: "",
        continent: "",
      },
      contact: {
        name: "",
        phone: "",
        email: "",
        website: "",
      },
      rooms: [],
      seasons: [
        {
          name: "Default",
          start: "",
          finish: "",
          range: [],
        },
      ],
      meals: {
        index: [],
        breakfast: {
          included: false,
          cost: 0,
        },
        lunch: {
          included: false,
          cost: 0,
        },
        dinner: {
          included: false,
          cost: 0,
        },
        guide: {
          included: false,
          cost: 0,
        },
      },
    },
    room: {
      index: null,
      description: "",
      basis: "",
      rates: [
        // {
        //   rack: 0,
        //   sto: 0,
        //   guide: 0,
        // },
      ],
    },
    // Common data
    loading: false,
    success: "",
    error: "",
    deleteDialog: false,
    deletionConfirmed: false,
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.id = this.$route.params.id;
      this.countries = require("@/assets/continents.json");
      this.currencies = require("@/assets/currencies.json");
      // Load the id if it exists
      if (this.id) {
        this.loading = true;
        const db = getFirestore();
        const collectionRef = collection(db, "hotels");
        const docRef = doc(collectionRef, this.id);
        onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            this.model = doc.data();
            this.model.id = doc.id;
            this.loading = false;
          } else {
            this.error = "Hotel not found.";
            this.id = null;
            this.model.id = null;
            this.loading = false;
          }
        });
      }
    },
    addRoomOption() {
      let room = JSON.parse(JSON.stringify(this.room));
      this.roomIndex = this.model.rooms.length; // don't need to -1 because we haven't pushed yet so
      room.index = this.roomIndex;
      // Add all seasons rates
      room.rates = [];
      this.model.seasons.forEach((season) => {
        room.rates.push({
          rack: 0,
          sto: 0,
          guide: 0,
        });
      });
      this.model.rooms.push(room);
    },
    addSeason() {
      this.seasonIndex = this.model.seasons.length; // don't need to -1 because we haven't pushed yet so
      // Add a new season to all rooms
      this.model.rooms.forEach((room) => {
        room.rates.push({
          rack: 0,
          sto: 0,
          guide: 0,
        });
      });
      //
      this.model.seasons.push({
        name: "Untitled",
        start: "",
        finish: "",
        range: [],
      });
    },
    filterChange() {
      this.model.meals.breakfast.included = this.model.meals.index.includes(0)
        ? true
        : false;
      this.model.meals.lunch.included = this.model.meals.index.includes(1)
        ? true
        : false;
      this.model.meals.dinner.included = this.model.meals.index.includes(2)
        ? true
        : false;
    },
    removeRoomOption(index) {
      this.model.rooms.splice(index, 1);
    },
    removeSeason(index) {
      // Loop through all room options and remove the rates for the given index
      this.model.rooms.forEach((room) => {
        room.rates.splice(index, 1);
      });
      // Remove the season
      this.model.seasons.splice(index, 1);
    },
    dateInput(season) {
      if (season.start && season.finish) {
        season.range = [season.start, season.finish];
      }
    },
    dateRangeInput(season) {
      if (season.range.length == 2) {
        season.start = season.range[0];
        season.finish = season.range[1];
      }
    },
    updateExchangeRate() {
      if (this.model.currency != undefined && this.model.currency.code) {
        let endpoint =
          "https://v6.exchangerate-api.com/v6/04107731562b261fdf9a93c8/pair/" +
          this.model.currency.code +
          "/CAD";
        axios.get(endpoint).then((response) => {
          if (response.status == 200 && response.data.result == "success") {
            this.model.currency.exchange = response.data.conversion_rate;
            this.$forceUpdate();
          } else {
            this.model.currency = null;
          }
        });
      }
    },
    create() {
      this.success = false;
      this.error = false;
      this.loading = true;
      const db = getFirestore();
      const collectionRef = collection(db, "hotels");
      addDoc(collectionRef, this.model)
        .then((docRef) => {
          this.success = "Hotel saved successfully.";
          this.model.id = docRef.id;
          this.id = this.model.id;
          this.loading = false;
        })
        .catch((error) => {
          this.error = "Error adding hotel. Please try again.";
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      const db = getFirestore();
      const collectionRef = collection(db, "hotels");
      const docRef = doc(collectionRef, this.model.id);
      setDoc(docRef, this.model)
        .then((docRef) => {
          this.success = "Hotel updated successfully.";
          this.loading = false;
        })
        .catch((error) => {
          this.error = "Error updating hotel. Please try again.";
          this.loading = false;
        });
    },
    permanentlyDeleteHotel() {
      this.loading = true;
      const db = getFirestore();
      const collectionRef = collection(db, "hotels");
      const docRef = doc(collectionRef, this.model.id);
      deleteDoc(docRef)
        .then((docRef) => {
          // this.success = "Hotel deleted successfully.";
          this.go('AdminVendorsHotels')
          this.loading = false;
        })
        .catch((error) => {
          this.error = "Error deleting hotel. Please try again.";
          this.loading = false;
        });
    },
  },
  watch: {
    user() {
      if (this.user) this.init();
    },
  },
  computed: {
    filtered_countries() {
      return this.countries.filter(
        (country) => country.continent == this.model.location.continent
      );
    },
  },
  filters: {
    formatCurrency(value) {
      // if (!value) return '$0'
      // if (value < 1000) return '$' + value
      // value = value.toFixed(2).toString().replace(/^0+/, '') // remove any leading zeros
      // return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return value.toFixed(2);
    },
  },
};
</script>
